<template>
  <el-popover
    popper-class="popover"
    placement="top-end"
    trigger="hover"
    width="400"
    >
    <div class="cont history">
      <el-tabs v-model="activeName" style="height: 100%;">
        <el-tab-pane name="0" style="height: 100%;position: relative;">
          <span slot="label" class="title t1">
            <el-badge :value="officeNum > 0 ? officeNum : ''" class="item" >
              Official notify
            </el-badge>
          </span>

          <div class="main_cont">
            <template v-if="officeData.length > 0">
              <div class="notify_item" v-for="item in officeData">
                <div class="top">
                  <div class="isnew" v-if="item.status == 1"></div>
                  <div class="_title">
                    {{  item.title  }}
                  </div>
                  <div class="date">{{ item.createdAt }}</div>
                </div>
                <div class="_bot">
                  {{  item.content  }}
                </div>
              </div>
            </template>
            <!-- 如果没有消息 -->
            <div class="empty" v-else>
              <el-image :style="{ height: 150 + 'px' , width: 150 + 'px'  }"
                :src="require('../assets/img/DrawingBoard/empty.png')"
                  fit="contain">
                <div slot="placeholder" class="image-slot"></div>
              </el-image>
              <div class="desc">no data</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="1" style="height: 100%;">
          <span slot="label" class="title t2">
            <el-badge :value="dongtaiNum > 0 ? dongtaiNum : ''" class="item" >
              dynamic
            </el-badge>
          </span>

          <div class="main_cont">
            <template v-if="newsData.length > 0">
              <div class="news_item" v-for="item in newsData">
                <div style="position: relative;">
                  <el-image  class="ava" 
                    @click="gotoPersonal(item)"
                    :src="item.photo" fit="cover" >
                    <div slot="placeholder" class="image-slot"></div>
                  </el-image>

                  <span  v-if="item.status == 1" style="position: absolute;right: 5px;top: -2px;border-radius: 50%;background: #EF4545;width: 8px;height: 8px;display: inline-block;"></span>

                </div>
                <div class="center">
                  <div class="t">
                    <div class="name" @click="gotoPersonal(item)">{{ item.uname }}</div>
                    <div class="value" v-if="item.type==1">Liked your work</div>
                    <div class="value" v-if="item.type==2">applied your work</div>
                    <div class="value" v-if="item.type==3">Followed you</div>

                  </div>
                  <div class="b">{{ item.createdAt }}</div>
                </div>

                <el-image  class="_img" 
                  v-if="item.content"
                  :src="JSON.parse(item.content).imgUrl" fit="cover" >
                  <div slot="placeholder" class="image-slot"></div>
                </el-image>
              </div>
            </template>
            <!-- 如果没有消息 -->
            <div class="empty" v-else>
              <el-image :style="{ height: 150 + 'px' , width: 150 + 'px'  }"
                :src="require('../assets/img/DrawingBoard/empty.png')"
                  fit="contain">
                <div slot="placeholder" class="image-slot"></div>
              </el-image>
              <div class="desc">no data</div>
            </div>



          </div>
        </el-tab-pane>
       </el-tabs>

       <span class="read" @click="readAll" v-if="totalMessageNum">Read all</span>
       <span class="read" v-else style="color: #49495A;">Read all</span>
    </div>



    <div class="btn_cont">
      <div class="_btn" @click="gotoMessageCenter" v-if="totalMessageNum">see all</div>
      <div class="_btn" @click="gotoMessageCenter" v-else-if=" officeData.length == 0 && newsData.length == 0" style="color: #49495A;">see all</div>
      <div class="_btn" @click="gotoMessageCenter" v-else>see all</div>
    </div>
    <!-- <el-image  slot="reference" style="width: 30px; height: 30px;margin-right: 1px;cursor: pointer;margin-bottom: -2px;" 
      :src="require('../assets/img/nav/info.png')" fit="contain" >
      <div slot="placeholder" class="image-slot"></div>
    </el-image> -->

    <el-badge :value="totalMessageNum ? totalMessageNum : ''" class="item" slot="reference">
      <el-image   style="width: 30px; height: 30px;margin-right: 1px;cursor: pointer;margin-bottom: -2px;" 
        :src="require('../assets/img/nav/infoact.png')" fit="contain"  v-if="totalMessageNum">
        <div slot="placeholder" class="image-slot"></div>
      </el-image>
      <el-image   style="width: 30px; height: 30px;margin-right: 1px;cursor: pointer;margin-bottom: -2px;" 
        :src="require('../assets/img/nav/info.png')" fit="contain"  v-else>
        <div slot="placeholder" class="image-slot"></div>
      </el-image>
    </el-badge>

  </el-popover>
</template>

<script>
import { msg_typeCount_req , msg_page_req , msg_home_req , msg_readpist_req } from "@/api/message";

export default {
  data() {
    return {
      activeName : 0 , 

      officeData : [] , 
      newsData :  [] ,
    }
  } ,
  computed: {
    totalMessageNum(){
      // 存储位置： store.state.{模块名,在store/index.js中module下的}.{state中定义的属性}
      return this.$store.state.message.totalMessageNum
    } ,
    officeNum(){
      return this.$store.state.message.officeNum
    },
    zanNum(){
      return this.$store.state.message.zanNum
    },
    guanzhuNum(){
      return this.$store.state.message.guanzhuNum
    },
    yingyongNum(){
      return this.$store.state.message.yingyongNum
    },
    dongtaiNum(){
      return this.zanNum + this.guanzhuNum + this.yingyongNum 
    }
  } ,
  mounted(){
    this.getMsgPage()
  } ,
  methods : {
    getMsgPage(){      
      msg_home_req().then( res => {
        this.officeData = res.data.system.records  ;
        this.newsData = res.data.trends.records;
      })
    } ,
    gotoMessageCenter(){
      if( this.officeData == 0 &&  this.newsData == 0 ) return
      this.$router.push({
        name: "MessageCenter", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      });
    },
    gotoPersonal( item ){
      let obj = {} ;
      obj.router = "personal"  ;
      obj.uid = item.uid ;
      this.$router.push({
        name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        params: obj
      });
    } ,
    imgitem_click( item ){
      // this.$store.commit('change_token') ;
      // if( !this.tokenVuex ){
      //   this.loginFormShow = true ;
      //   this.$emit('loginFormShowChange', true );
      //   return;
      // }
      let obj = {} ; 

      obj.router = "explore"
      obj._id = item.id
      this.$router.push({
        name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        params: obj
      });
    },
    readAll(){
      msg_readpist_req([1 ,2 ,3]).then( _res => {
        this.$store.dispatch('message/changeMesNumAct');

        this.getMsgPage()
      })
    }
  },

}
</script>

<style lang="scss" scoped>
::v-deep .el-badge__content {
  border: none;
  background-color: #EF4545 ;
}
::v-deep .el-badge__content.is-fixed {
  top: 7px;
  right: 12px;
}
::v-deep .title  .el-badge__content.is-fixed {
  top: 7px;
  right: 3px !important;
}
.cont {
  padding: 4px 12px;
}

::v-deep .el-tabs__content {
  height: calc(100% - 55px);
}

::v-deep .el-tabs__item {
  color: #CACAD4;
  font-weight: 400;
  padding: 0;
}

::v-deep .el-tabs__item.is-active {
  color: #fff;
  font-weight: 500;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: #0B0B1e;
  height: 0;
}


.history {
  text-align: left;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  overflow: hidden;
  height: 100%;
  position: relative;
  .read {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 56px;
    height: 20px;
    font-size: 14px;
    color: #006FFF;
    line-height: 20px;
    cursor: pointer;
  }

  .title {
    box-sizing: border-box;
    padding: 0 15px;
    font-size: 16px;
    position: relative;
  }

  ::v-deep .el-tabs__item.is-active .title:after {
    content: "";
    display: block;
    width: 20px;
    height: 4px;
    background: #006FFF;
    border-radius: 2px;
    position: absolute;
    bottom: -5px;
  }

  ::v-deep .el-tabs__active-bar {
    background-color: transparent !important
  }

  ::v-deep .el-tabs__item.is-active .t1:after {
    left: 36px;
  }

  ::v-deep .el-tabs__item.is-active .t2:after {
    left: 21px;
  }

  ::v-deep .el-tabs__header.is-top {
    margin-bottom: 0;
  }
  ::v-deep .el-tabs__item:hover {
    color: #fff;
  }

  .scroll-cont {
    height: calc(100% );
    position: relative;
  }

  .scroll-cont {
    overflow: hidden;
  }

  .scroll-cont::-webkit-scrollbar {
    width: 5px;
    display: flex;
  }

  .scroll-cont::-webkit-scrollbar-thumb {}

  .scroll-cont:hover {
    overflow-y: auto;
    // width: calc(100% - 1px )
  }
  .his_item_container {
    padding: 0 100px 0 84px;
  }
  .his-item {
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 148px;
    margin-bottom: 5px;
    align-content: stretch;
    position: relative;
    overflow: hidden;
    padding-bottom: 24px;
    padding-top: 28px;
    border-bottom: 1px solid #252739;
    .imgB1 {
      width: 130px;
      height: 130px;
    }

    .imgB2 {
      width: 65px;
      height: 130px;
    }

    .imgB3 {
      width: 65px;
      height: 65px;
    }

    .imgB4 {
      width: 65px;
      height: 65px;
    }

    img {
      width: 50%;
      height: 50%;
    }
    .top_model {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      height: 34px;
      background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
      z-index: 10;
      display: none;
    }
    .deleteIcon {
      display: none;
    }


    .c {
				flex: 1;
				.ct {
					display: flex;
					align-items: center;
					height: 40rpx;
					.ct_name {
						height: 28px;
						width: 100%;
						font-size: 20px;
						font-weight: 500;
						line-height: 28px;
						display: flex;
						align-items: center;
						overflow:hidden; //超出的文本隐藏
						text-overflow:ellipsis; //溢出用省略号显示
						white-space:nowrap; //溢出不换行
            color: #fff;
					}
				}
				.cb {
          height: 22px;
          font-size: 16px;
					color: #9A9AB7;
					line-height: 22px;
					margin-top: 2px;
          margin-bottom: 10px;
				}
			}
      .img_cont {
        height: 100px;
        display: flex;
        ._img {
          margin-left: 6px;
          width: 100px;
          height: 100px;
        }
        ._img:first-child {
          border-radius: 10px 0px 0px 10px;
        }
        ._img:last-child {
          border-radius: 0px 10px 10px 0px;
        }
      }
			.btnfoucs {
				width: 88px;
				height: 34px;
				line-height: 34px;
				background: #252739;
				border-radius: 17px;
				text-align: center;
				line-height: 34px;
				font-size: 16px;
				font-weight: 500;
				color: #9A9AB7;
			}
			.btnUnfocus {
				width: 88px;
				height: 34px;
				background: linear-gradient(315deg, #006FFF 0%, #72E3F5 100%);
				border-radius: 17px;
				text-align: center;
				line-height: 34px;
				font-size: 16px;
				font-weight: 500;
				color: #FFFFFF;
			}
  }

  .his-item:hover {
    .top_model {
      display: block;;
    }
    .deleteIcon {
      display: block;
    }
  }
}

.main_cont {
  height: 354px;
  width: 100%;
  overflow-y: auto;
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .desc {
      height: 22px;
      color: #49495A;
      line-height: 22px;
      text-align: center;
    }
  }

  .notify_item {
    padding: 12px 15px;
    height: 82px;
    width: 100%;
    box-sizing: border-box;
    .top {
      height: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 20px;
      margin-bottom: 5px;
      .isnew{
        width: 8px;
        height: 8px;
        background: #EF4545;
        border-radius: 50%;
        margin-right: 6px;
      }
      ._title {
        flex: 1;
        margin-right: 13px;
        font-size: 14px;
        color: #FFFFFF;
        text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

      }
      .date {
        color: #9A9AB7;
        scale: 0.9;
      }
    }
    ._bot {
      width: 100%;
      height: 34px;
      font-size: 12px;
      color: #9A9AB7;
      line-height: 17px;
      display:  -webkit-box;
      -webkit-line-clamp:  2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow : hidden;

      position: relative;
      // line-height: 24px;
      max-height:48px;
      // overflow: hidden;
    }


    
  }

  .news_item {
    padding: 12px 15px;
    height: 66px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .ava {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      margin-right: 8px;
      flex-shrink: 0;
    }
    .center {
      height: 40px;
      flex: 1;
      min-width: 0; //新增
      .t {
        height: 20px;
        width: 100%;
        display: flex;
        .name {
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #006FFF;
          line-height: 20px;
          margin-right: 4px;
          overflow:hidden; //超出的文本隐藏
          text-overflow:ellipsis; //溢出用省略号显示
          white-space:nowrap; //溢出不换行
          cursor: pointer;
        }
        .value {
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          line-height: 20px;
          flex-shrink: 0;
        }
      }
      .b {
        height: 16px;
        font-size: 12px;
        color: #9A9AB7;
        line-height: 16px;
      }
    }
    ._img{
      width: 54px;
      height: 54px;
      border-radius: 8px;
      margin-left: 7px;
      flex-shrink: 0;
    }
  }
}
.btn_cont {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ._btn {
    width: 306px;
    height: 38px;
    line-height: 38px;
    background: #252739;
    border-radius: 19px;
    font-size: 16px;
    font-weight: 500;
    color: #9A9AB7;
    text-align: center;
    cursor: pointer;
  }
}


</style>
