<template>
  <el-dialog class="dialog" :visible.sync="dialogAccountSettingsShow" :before-close="handleClose" :show-close="false"
    append-to-body width="584px" :close-on-click-modal="false">
    <div class="cont">
      <div class="title d-flex">
        <span style="margin: 0 7px;">Profile</span>
        <el-image style="width: 24px; height: 24px;cursor: pointer;position: absolute;right: -20px;top: -6px;"
          @click="handleClose()" :src="require('../assets/img/recharge/close.png')" fit="contain">
          <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
        </el-image>
      </div>
      <div class="cont1" style="margin-top: 14px;">
        <div style="height: 17px;line-height: 17px;display: flex;justify-content: flex-start;margin-top: 14px;color: #CACAD4;">
          <span style="font-size: 16px;">Username</span>
          <el-image style="width: 16px; height: 16px;cursor: pointer;"
            :src="require('../assets/img/feedback/must.png')" fit="contain">
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
        </div>
        <div class="d-flex text-cont" style="align-items: center;margin-bottom: 20px;min-height: 40px;margin-top: 14px;">
          <div style="flex:1">
            <el-input resize="none" type="textarea" :autosize="{ minRows: 1, maxRows: 1 }" placeholder="Please enter your username"
              v-model="uname" style="background-color: #1B1D2E;min-height: 40px;line-height: 40px;border-radius: 10px;padding-top: 3px;"></el-input>
          </div>
        </div>
        
        <div style="height: 17px;line-height: 17px;display: flex;justify-content: flex-start;margin-top: 14px;color: #CACAD4;margin-bottom: 14px;">
          <span style="font-size: 16px;">Profile Image</span>
        </div>

        <div class="d-flex img_upload">
          <el-image style="width: 90px; height: 90px;cursor: pointer;margin-right: 16px;flex-shrink: 0;border-radius: 50%;"
            fit="cover"
            :src="'data:image/png;base64,' + proofImage"  v-if="proofImage"
            >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
          
          <!-- <el-image style="width: 90px; height: 90px;cursor: pointer;margin-right: 16px;flex-shrink: 0;border-radius: 50%;"
            :src="require('../assets/img/feedback/must.png')" fit="cover"
            v-else-if="ac_data && ac_data.ext"
            >
            <div slot="placeholder" class="image-slot"></div>
          </el-image> -->
          <el-image style="width: 90px; height: 90px;cursor: pointer;margin-right: 16px;flex-shrink: 0;border-radius: 50%;"
            :src="ac_data.photo" fit="cover"
            v-else-if="ac_data && ac_data.photo"
            >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
          <el-image style="width: 90px; height: 90px;cursor: pointer;margin-right: 16px;flex-shrink: 0;border-radius: 50%;"
            :src="require('../assets/img/ava.png')" fit="cover"
            v-else-if="ac_data && !ac_data.photo"
            >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
          
          <div style="height: 94px;flex: 1;border: 1px dashed #CACAD4;border-radius: 10px; ">
            <el-upload
              class="upload-demo"
              :auto-upload="false"
              drag
              action=""
              :on-change="getFile"
              style="height: 90px;"
              :show-file-list="false"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PNG,.GIF,.BMP"
            >
              <div class="el-upload__text">Drop image here or click to upload</div>
            </el-upload>
          </div>
        </div>

        <div style="height: 17px;line-height: 17px;display: flex;justify-content: flex-start;margin-top: 20px;color: #CACAD4;">
          <span style="font-size: 16px;">Personal profile</span>
        </div>
        <div class="d-flex text-cont" style="align-items: center;margin-bottom: 20px;min-height: 40px;margin-top: 14px;">
          <div style="flex:1">
            <el-input resize="none" type="textarea" :autosize="{ minRows: 1, maxRows: 5 }" placeholder="Tell me about yourself"
              v-model="remark" style="background-color: #1B1D2E;min-height: 40px;line-height: 40px;height: 120px;"></el-input>
          </div>
        </div>
        
        <div class="d-flex" style="justify-content: flex-end;margin-top: 24px;">
          <el-button type="primary" round style="background: #25293C;border: 1px solid #25293C;color: #666666;width: 130px;"
            autofocus @click="handleClose">Cancel</el-button>
          <el-button type="primary" round style="background: #006FFF;border: 1px solid #006FFF;color: #fff;width: 130px;"
            autofocus @click="save">Save</el-button>
        </div>
      </div>

      <div class="cont1" style="margin-top: 14px;">
        <div style="font-size: 16px;font-weight: 500;color: #FFFFFF;line-height: 22px;margin-bottom: 12px;align-items: center;" class="d-flex">
          <div style="flex: 1;">
            Mature Content
          </div>
          <el-switch
            v-model="adultSwitch"
            active-color="#006fff"  
            @change="adultSwitchChange"
          >
          </el-switch>
        </div>  
        <div class="d-flex">
          <el-image style="width: 36px; height: 36px;cursor: pointer;margin-right: 10px;"
          :src="require('../assets/img/nav/18forbid.png')" fit="contain">
            <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
          </el-image>
          <div style="font-size: 15px;color: #CACAD4;line-height: 21px;overflow-wrap: break-word;word-break: break-word;">
            By enabling Mature Content, you confirm you are over the age of 18.
          </div>
        </div>
      </div>

    </div>
  </el-dialog>

</template>

<script>
import { sms_req , login_req , login_out_res , user_account_req , user_photo_req , user_setting_req , user_adultSwitch_req } from "@/api/user";
import { growth_tasks_req , growth_publish_req } from "@/api/task";

export default {
  props: {
    dialogAccountSettingsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      proofImage : '',
      ac_data : undefined , 
      adultSwitch : false , 
      file : undefined ,
      uname : undefined , 
      remark : undefined , 

    }
  },
  methods : {
    handleClose(done) {
      this.$store.commit('user/change_accountSetting' , false ) 
      this.$emit('update:dialogAccountSettingsShow', false) // 传递更新数值
    },
    async getUserAccountInfo(){
      const { data , success} = await user_account_req()
      if( success ) {
        this.adultSwitch = data.adultSwitch ?   true  : false
        this.uname = data.uname ? data.uname : '' ;
        this.remark = data.remark ? data.remark : "" ;
        this.ac_data = data
      }
    },
    beforeUpload(){

    },
    getFile(file, fileList , scope) {
      let imgSize = Number(file.size / 1024 / 1024);
      if (imgSize > 6) {
        this.$toast.open({
          message: 'The maximum image size is 6MB, please try again',
          position: 'top',
          duration: 3000,
          type: 'error',
        });
        return
      }

      if (fileList&&fileList.length>=2) {
        fileList.shift();
      }
      this.file = file ; 

      this.getBase64(file.raw).then(res => {
        const params = res.split(',')
        if (params.length > 0) {
          this.proofImage = params[1]
        }
      })
    },
    // 获取图片转base64
    getBase64(file) {
      let _this = this ;
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function (e) {
          imgResult = reader.result

        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    async save(){
      if( !this.uname ){
        this.$toast.open({
          message: "Please enter information before submitting",
          position: 'top',
          duration: 3000,
          type: 'error',
        });
        return 
      }

        // if( this.uname ){
        //   formData.append( 'uname' , this.uname );
        // } else {
        //   formData.append( 'uname' , '' );
        // }
        // formData.append( 'uid' , this.ac_data.uid );



      if( !this.file  &&  !this.uname && !this.remark ){
        this.$toast.open({
          message: "Please enter information before submitting",
          position: 'top',
          duration: 3000,
          type: 'error',
        });
      } else if( this.file ){
        let formData = new FormData();
        if( this.file ){
          formData.append('file',  this.file.raw , "ava."+ this.file.name.split(".").pop() );
        }
        const loading = this.$loading({
          lock: true,
          text: 'Uploading...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)' ,
          customClass : "my_loading" 
        });

        let {  data : pdata , success : psuccess } = await user_photo_req(formData);
        if( pdata ){
          let {  data  , success  } = await user_setting_req({
            uname : this.uname ? this.uname : '' ,
            remark : this.remark ? this.remark : '' ,
            uid : this.ac_data.uid , 
            photo : pdata ,
          })

          // let { data , success } = await user_setting_req( formData );
          loading.close();
          if( success ) {
            this.$toast.open({ message: 'Uploaded', position: 'top', duration: 3000, type: 'success' });
            growth_tasks_req({type : 6 }).then( res1 => {
              if(  res1.data[0].total == res1.data[0].count  ){
                setTimeout( ()=>{
                  this.$toast.open({ message: 'Completed personal information, 5 points have been received', position: 'top', duration: 3000, type: 'success' });
                } , 1000 )
              }
            })
          } else {
            this.$toast.open({ message: 'Upload failed, please try again later', position: 'top', duration: 3000, type: 'error' });
          }
          this.handleClose();
          this.$store.commit('user/change_info') 
        }else {
          loading.close();
        }
        this.$emit('userChangeListen', true );
      } else if( !this.file  ){
        const loading = this.$loading({
          lock: true,
          text: 'Uploaded...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)' ,
          customClass : "my_loading" 
        });
        let {  data  , success  } = await user_setting_req({
          uname : this.uname ? this.uname : '' ,
          remark : this.remark ? this.remark : '' ,
          uid : this.ac_data.uid , 
          photo : this.ac_data.photo  
        })

        loading.close();
        if( success ) {
          this.$toast.open({ message: 'Uploaded', position: 'top', duration: 3000, type: 'success' });
          growth_tasks_req({type : 6 }).then( res1 => {
              if(  res1.data[0].total == res1.data[0].count  ){
                setTimeout( ()=>{
                  this.$toast.open({ message: 'Completed personal information, 5 points have been received', position: 'top', duration: 3000, type: 'success' });
                } , 1000 )
              }
            })

        } else {
          this.$toast.open({ message: 'Upload failed, please try again later', position: 'top', duration: 3000, type: 'error' });
        }
        this.handleClose();
        this.$emit('userChangeListen', true );
        this.$store.commit('user/change_info') 
      } 
    },
    adultSwitchChange(){
      user_adultSwitch_req(  this.ac_data.uid , this.adultSwitch ).then( res=>{
        this.$emit('adultSwitchChangeListen', true );
      })
    }

  }, 
  computed: {
    tokenVuex(){
      // 存储位置： store.state.{模块名,在store/index.js中module下的}.{state中定义的属性}
      return this.$store.state.user.token
    }
  },
  mounted() {
    // this.navdata = common.getNavData()
    if( this.tokenVuex ){ //如果登录了 去获取信息
      this.getUserAccountInfo()
    }
  },  
}

</script>

<style lang="scss" scoped>  
::v-deep .el-dialog {
    min-width: 460px;
    // position: relative;
    // background-color: palegreen;
    background-color: #1B1D2E !important;
    box-shadow: none;

    display: flex;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    border-radius: 20px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;

      .cont {
        width: 580px;
        height: 703px;
        padding: 20px 34px;
        box-sizing: border-box;

        .title {
          height: 30px;
          line-height: 30px;
          width: 100%;
          position: relative;
          justify-content: center;
          align-items: center;
          font-size: 21px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 29px;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        }

        .sub_title {
          width: 100%;
          font-size: 15px;
          font-weight: 400;
          color: #CACAD4;
          line-height: 21px;
          margin-top: 9px;
          margin-bottom: 14px;
          text-align: center;
        }

        .subject {
          margin-bottom: 13px;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
        }

        .cont1 {
          background: #111225;
          border-radius: 10px;
          border: 1px solid #25293C;
          padding: 14px 20px;

          .img_upload {
            .el-upload-dragger {
              height: 90px;
            }
          }
        }
      }
    }
  }



  ::v-deep .img_upload {
    .el-upload-dragger {
      height: 90px;
      background: #111225;
      border: none;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  ::v-deep .el-textarea.is-disabled .el-textarea__inner {
    background: #111225;
    margin-top: 3px;
  }

  ::v-deep .el-textarea__inner {
    background: #1B1D2E;
    border: none;
    color: #fff;

    &::placeholder {
      color: #666;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers 适配谷歌 */
      color: #666;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 适配火狐 */
      color: #666;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ 适配火狐 */
      color: #666;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10+  适配ie*/
      color: #666;
    }
  }




  ::v-deep .avatar-uploader .el-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    background: rgba(155, 184, 220, 0.08);
    border-radius: 10px;
    color: #CACAD4;
    margin-right: 12px;
  }

 .replyListCont {
  .replyItem {
    padding: 12px 16px;
    box-sizing: border-box ;
    background: #111225;
    margin-bottom: 20px;
    .date {
      font-size: 14px;
      font-weight: 500;
      color: #9696A2;
      line-height: 20px;
    }
    .line {
      font-size: 14px;
      font-weight: 400;
      color: #CACAD4;
      line-height: 20px;
    }
    .answer {
      background: #1B1D2E;
      border-radius: 10px;
      padding: 10px 14px;
      font-size: 13px;
      font-weight: 400;
      color: #9696A2;
      line-height: 18px;
      // background: #111225;
      margin-top: 16px;
      background: #1B1D2E;

    }
    .icon {
      background: #F5B109
    }
    .activedIcon {
      background : #12D107
    }
  }
 } 
</style>
