import request from '@/utils/request'
import axios from 'axios'

export function msg_typeCount_req( query ){
  return request({
    url:  '/msg/typeCount',
    method: 'get',
    // params: query,
  })
}


export function msg_page_req( data ){
  return request({
    url:  '/msg/page',
    method: 'post',
    data: data,
  })
}


export function msg_home_req( data ){
  return request({
    url:  '/msg/home',
    method: 'get',
  })
}

export function msg_read_req( data ){
  return request({
    url:  '/msg/read/' + data,
    method: 'get',
  })
}

export function msg_readpist_req( data ){
  return request({
    url:  '/msg/read' ,
    method: 'post',
    data: data,
  })
}
