import request from '@/utils/request'
import axios from 'axios'

export function guestbookSaveReq(data) {
  return request({
    url:  '/guestbook/save',
    method: 'post',
    data: data,
  })
}

export function guestbook_page_req(data) {
  return request({
    url:  '/guestbook/page',
    method: 'post',
    data: data,
  })
}