<template>
  <el-dialog class="dialog" :visible.sync="newRechargeShow" :before-close="handleClose" :show-close="false">
    <div class="main">
      <div class="btn_cont">
        <div class="_btn _btn1" @click="handleClose">leave</div>
        <div class="_btn _btn2" @click="gotoRecharge">purchase</div>
      </div>
    </div>
  </el-dialog>
</template>


<script>



export default {
  props: {
    newRechargeShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },
  methods: {
    handleClose(done) {
      this.$emit('update:newRechargeShow', false) // 传递更新数值
    },
    gotoRecharge(){
      this.$emit('gotoRecharge');
    },
    async onSubmit() {

    },
  },
  computed: {

  },
  mounted() {

  },

};
</script>

<style lang="scss" scoped>  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #006FFF;
    border-color: #006FFF;
    border-radius: 50%;
  }

  ::v-deep .el-checkbox__input .el-checkbox__inner {
    border-color: #fff;
    border-radius: 50%;
  }

  ::v-deep .el-dialog {
    width: 690px;
    height: 690px;
    position: relative;
    // background-color: palegreen;
    background-color: rgba(0, 0, 0, 0) !important;
    background-image:url("../assets/img/recharge/newRecharge.png") !important;
    background-size: 100%;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }

  ::v-deep .el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
    padding: 0;
  }
  ::v-deep .el-dialog .el-dialog__header {
    padding: 0;
  }

  //::v-deep .el-dialog
  // ::v-deep .el-dialog::-webkit-scrollbar-thumb {  
  //   background-color: #000;
  // } 


  ::v-deep .el-dialog .main {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    // background-image:url("../../public/assets/img/login/bg1.png") !important ;
    padding: 40px;
    box-sizing: border-box;
    // background-color: rgba(0, 0, 0, 0.4);
    border-radius: 33px;
    // backdrop-filter: blur(8px);
    .btn_cont {
      position: absolute;
      left: 205px;
      bottom: 180px;
      width: 284px;
      height: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      ._btn {
        width: 134px;
        height: 50px;
        line-height: 50px;
        border-radius: 30px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;        
        color: #05254D;
        border-radius: 30px;
        cursor: pointer;
        &._btn1 {
          background: #CCE2FF;
        }
        &._btn2 {
          background: linear-gradient(315deg, #006FFF 0%, #72E3F5 100%);
        }
      }
    }

    .title {
      font-size: 24px;
      font-weight: 500;
      color: #FFFFFF;
      margin-top: 26px;
      position: relative;
    }

    .line_out {
      position: relative;

      .icon {
        position: absolute;
        top: 15px;
        left: 18px;
      }
    }

    .line {
      width: 370px;
      height: 50px;
      background: rgba(34, 35, 55, 0);
      border-radius: 33px;
      box-sizing: border-box;
    }

    .el-input__inner {
      height: 50px;
      border-radius: 33px;
      border: 2px solid #7D7D9B;
      background: rgba(34, 35, 55, 0);
      padding: 0 120px 0 50px;
      color: #fff;
    }

    .el-input__inner:focus {
      border-color: #006FFF;
    }

    .protocol {
      margin-top: 22px;
    }

    .code_error .el-input__inner {
      border-color: red;
      color: red;
    }
  }
</style>