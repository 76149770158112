export default {
  state : {
      // 这里放要管理的状态
      isCollapse : false ,
      form: {
        model_type : 1 , 
        model : undefined,
        style : 0,
        batch_size : 1 , 
        prompt : undefined,
        negative_prompt : undefined , 
        style_ratio : 0.6 , 
        width : 512 , 
        height : 512 , 
        is_public : 1 , 
        steps : 1 ,
        sampler_name : undefined , 
        
        enable_hr : false , 
        denoising_strength : 0.45 , 
        hr_scale : 1.25 ,   //str
        hr_second_pass_steps : 0 ,  //str
        hr_upscaler : "Latent" , 
        cfg_scale : 7 , 
        seed : -1 , 
        width_height : undefined , 
        from : undefined ,
        from_uid : undefined , 
      },
  },
  mutations:{
      // 定义待发送的事件，第一个参数会注入当前的state
      exchange(state){
          state.isCollapse = !state.isCollapse
      } ,
      changeTextImageForm(state , data ) {
        state.form = data
      } ,
  }
}

