<template>
  <el-dialog
    class="dialog"
    :visible.sync="dialogLoginVisible"
    :before-close="handleClose"
    :show-close="false"
    >
    <!-- <div class="left">
      <div style="white-space: pre-wrap;" class="left_title">{{title }}</div>
    </div> -->
    <div class="main" v-show="logintype == 1 ">
      <div class="title">Login to Boser AI</div>

      <!-- @click="onSubmit" -->
      <b-button block  pill    size="lg" style="margin-top: 25px;background: rgba(0,0,0,0.4);align-items: center;justify-content: center;"  @click="googleLogin" class="d-flex">  
        <el-image style="width: 20px; height: 20px;cursor: pointer;margin-right: 8px;"
          :src="require('../assets/img/login/google.png')"
           fit="contain" >
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        Login with Google 
      </b-button>
      <div class="protocol">
        <div>
          Signing in means you agree to our 
        </div>
        <span style="color: #006FFF;cursor: pointer;" @click="jump('https://canvascraft.art/UserprivacyAgreement.html')">Service Agreement and Privacy Policy</span>  
      </div>

      <div style="margin-top: 22px;">
        <el-checkbox v-model="form.days" fill="#006FFF" ></el-checkbox> <span style="margin-left: 4px;">No login required for 14 days for the account</span> 
      </div>

    </div>
    <div class="main" v-show="logintype == 2 ">
      <div class="title d-flex" style="align-items: center;">
        <el-image style="width: 28px; height: 28px;cursor: pointer;margin-right: 7px;"
          @click="logintype = 1 " 
          :src="require('../assets/img/login/leftarrow.png')"
           fit="contain" >
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        微信登录</div>
        <div style="padding: 33px;">
          <wxlogin
            theme=''
            id="wxcode"
            v-if="appid && redirect_uri"
            :appid="appid"
            scope="snsapi_login"
            :redirect_uri="redirect_uri"
            :style="style"
            :href="href"
            :state="state"
        ></wxlogin>

        <!-- <qrcodevue :value="'weixin://wxpay/bizpayurl?pr=Cn1pSz6zz'" :size="300"></qrcodevue> -->

      </div>



    </div>
  </el-dialog>
</template>


<script>
import { sms_req , login_req , login_out_res , user_account_req  ,  wx_login_req  , gg_login_req } from "@/api/user";
import { getUUID  , delCookie } from "@/utils/bose";
import wxlogin from 'vue-wxlogin'



export default {
    props : {
      dialogLoginVisible: {
        type: Boolean ,
        default: false , 
      },
    }, 
    components: { wxlogin },

    data() {
      return {
        title : '上海博色\n信息科技有限公司' , 
        input : '' ,
        form: {
          tel: null,
          code: null,
          cnl : localStorage.getItem("cc") ? localStorage.getItem("cc") + '-pc': 'none-pc' , 
          bind : localStorage.getItem("bd_vid_url") ? localStorage.getItem("bd_vid_url") : undefined , 
          days : false  ,
        },
        bVerification : false ,
        countDown: "发送验证码", // 倒计时
        codeFlag : true , 
        logintype : 1 , 

        appid: 'wxfcb0ac7daa4527ab',
        redirect_uri: encodeURIComponent( 'https://aigc.umeweb.cn/boserai/common/wx/' ),
        state: getUUID(),
        href: ''  , // 自定义样式链接
        style : 'black' ,
        href : 'data:text/css;base64,LmltcG93ZXJCb3gge2Rpc3BsYXk6IGZsZXg7fQouaW1wb3dlckJveCAucXJjb2RlIHt3aWR0aDogMzAwcHg7IGhlaWdodDogMzAwcHh9Ci5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9Ci5pbXBvd2VyQm94IC5pbmZvIHtkaXNwbGF5OiBub25lO30KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lfQ=='
      };
    },
    methods: {
      googleLogin() {
        delCookie('g_state');
        google.accounts.id.initialize({
          client_id: '619759958483-vimgi4eiok4hfijsq78fhdf9g18e9p89.apps.googleusercontent.com',
          callback: handleCredentialResponse
        });
        google.accounts.id.prompt();
        let _this = this ; 
        async function handleCredentialResponse( res ){
          console.log( res )

          _this.form.code =  res.credential 
          _this.form.clientId = res.clientId;
          _this.form.clientId = '619759958483-vimgi4eiok4hfijsq78fhdf9g18e9p89.apps.googleusercontent.com'


          const { success , data } = await gg_login_req(_this.form);
          if ( success && data ) {
            localStorage.setItem( "x-token",  data  )
            
            _this.$toast.open({
              message: 'Login Successful',
              position: 'top',
              duration : 3000,
              type: 'success',
            });
            _this.$store.commit('user/change_token') ;
            _this.$store.dispatch('message/changeMesNumAct');
            _this.handleClose();
            _this.resetForm()
          } else {
            _this.codeFlag = false ;
          }
        }
      } ,
      handleClose(done) {
        this.resetForm();
        this.bVerification = false ;
        this.countDown = "发送验证码"  // 倒计时
        this.codeFlag = true ;
        this.$emit('update:dialogLoginVisible',  false ) // 传递更新数值
      } ,
      resetForm() {
        this.form = {
          tel: null,
          code: null,
          cnl : localStorage.getItem("cc") ? localStorage.getItem("cc") + '-pc': 'none-pc' , 
          days : false ,
        };
        this.bVerification = false // 节流
      },
      async onSubmit() {
        if( !this.validationPhone ){
          this.$toast.open({
            message: '请检查手机号格式' ,
            position: 'top',
            duration : 3000,
            queue : true   , 
            type: 'error',
          })
          return
        }
        if( !this.form.code ){
          this.$toast.open({
            message: '请检查验证码格式' ,
            position: 'top',
            duration : 3000,
            queue : true   , 
            type: 'error',
          })
          this.codeFlag = false ;
          return
        }

        // console.log( this.form)
        const { success , data } = await login_req( this.form );
        if ( success && data ) {
          localStorage.setItem( "x-token",  data  )
          
          this.$toast.open({
            message: 'Login Successful',
            position: 'top',
            duration : 3000,
            type: 'success',
          });
          this.$store.commit('user/change_token') ;
          this.$store.dispatch('message/changeMesNumAct');
          this.handleClose();
          this.resetForm()
        } else {
          this.codeFlag = false ;
        }
      } ,
      async sendCode() {
          if( !this.validationPhone ){
            this.$toast.open({
              message: '请检查手机号格式' ,
              position: 'top',
              duration : 3000,
              queue : true   , 
              type: 'error',
            })
            return
          }
          if (this.bVerification) return;
          this.bVerification = true;
          this.countDown = 60
          const { success , data } = await sms_req({
              tel: this.form.tel
          });
          if (success === true && data  === true ) {
            this.$toast.open({
              message: '发送验证码',
              position: 'top',
              duration : 3000,
              type: 'success',
            });
            let countDown = 59;
            const auth_time = setInterval(() => {
                this.countDown = countDown--;
                if (this.countDown <= 0) {
                    this.bVerification = false;
                    this.countDown = "发送验证码";
                    clearInterval(auth_time);
                }
            }, 1000);
          }  else {
            this.bVerification = false;
            this.countDown = "发送验证码";
          }
      } ,
      codeChange(){
        this.codeFlag = true ;
      } ,
      async wxLoginMethod( state ){
        const {data , success } = await wx_login_req({ state : state  })
        if ( success && data ) {
          localStorage.setItem( "x-token",  data  )
          
          this.$toast.open({
            message: 'Login Successful',
            position: 'top',
            duration : 3000,
            type: 'success',
          });
          this.$store.commit('user/change_token') ;
          this.$store.dispatch('message/changeMesNumAct');
          let obj = {} ;
          obj.router = "home"
          this.$router.replace({
            name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
            params: obj
          });



        } else {
          this.codeFlag = false ;
        }



      }, 
      jump( url ){
        window.open(url,"_blank")
      }
    } ,
    computed: {
      validationPhone() {
        var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!myreg.test(this.form.tel)) {
          return false;
        } else {
          return true;
        }
      }
    },
    mounted(){
      this.resetForm();
      this.bVerification = false ;
      this.countDown = "发送验证码"  // 倒计时
      this.codeFlag = true ;
      

      if( this.$route.query.result == "success" && this.$route.query.state  ) {
        this.wxLoginMethod( this.$route.query.state  )
      }



    },

  };
</script>

<style lang="scss" scoped>  

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: #006FFF;
  border-color : #006FFF;
  border-radius: 50%;
}
::v-deep .el-checkbox__input .el-checkbox__inner {
  border-color : #fff;
  border-radius: 50%;
}

::v-deep .el-dialog {
  width: 450px;
  height: 420px;
  position: relative;
  // background-color: palegreen;
  background-color:rgba(0,0,0,0) !important;
  box-shadow : none ;
  // background-image:url("../assets/img/login/bg.png") !important;

  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
::v-deep  .el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
//::v-deep .el-dialog
// ::v-deep .el-dialog::-webkit-scrollbar-thumb {  
//   background-color: #000;
// } 

::v-deep .el-dialog .left {
  width: 320px;
  height: 438px;
  position: absolute;
  top: 43px;
  left: 0;
  z-index: 98;
  .left_title {
      font-size: 21px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 29px;
      position: absolute;
      left: 26px;
      top: 144px;
    }

}
::v-deep .el-dialog .main {
  width: 450px;
  height: 420px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  // background-image:url("../../public/assets/img/login/bg1.png") !important ;
  padding: 40px;
  box-sizing: border-box;
  background-color:rgba(0,0,0,0.4);
  border-radius: 33px;
  backdrop-filter : blur(8px);

  .title {
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 26px;
    position: relative;
  }

  .line_out {
    position: relative;
    .icon {
      position: absolute;
      top: 15px;
      left: 18px;
    }
  }
  .line {
    width: 370px;
    height: 50px;
    background: rgba(34,35,55,0);
    border-radius: 33px;
    box-sizing: border-box;
  }
  .el-input__inner {
    height: 50px ;
    border-radius: 33px ;
    border: 2px solid #7D7D9B ;
    background: rgba(34,35,55,0) ;
    padding: 0 120px 0 50px ;
    color: #fff;
  }
  .el-input__inner:focus {
    border-color: #006FFF;
  }
  .protocol {
    margin-top: 42px;
    text-align: center;
  }

  .code_error .el-input__inner {
    border-color: red;
    color: red;
  }
}



</style>