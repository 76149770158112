<template>
  <el-popover
    popper-class="popover"
    placement="top-end"
    trigger="hover"
    width="356"
    :close-delay="1"
    @show="popShow"
    >
    <div style="width: 332px;height: 350px;padding: 12px 4px 2px 12px;box-sizing: border-box;" >
      <div style="height: 48px;margin-bottom: 14px;" class="d-flex">
        <el-image style="width: 48px; height: 48px;border-radius: 50%;margin-right: 8px;flex-shrink: 0;"
          v-if="acData.photo"
          :src="acData.photo"
            fit="cover">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <el-image style="width: 48px; height: 48px;border-radius: 50%;margin-right: 8px;flex-shrink: 0;"
          v-else
          :src="require('../assets/img/ava.png')"
            fit="cover">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>

        <div style="margin-bottom: 5px;margin-left: 0px;overflow: visible;max-width: 243px;" >
          <div style="height: 22px;align-items: center;margin-bottom: 5px;max-width: 243px;margin-top: 2px;" class="d-flex">
            <span v-if="acData && acData.uname"  style="font-size: 16px;margin-right: 4px;font-weight: 500;color: #FFF;max-width: 150px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
              {{ acData.uname }}
            </span>

            <el-image style="width: 26px; height: 14px;margin-right: 2px;margin-left: 4px;" 
              :src="require('../assets/img/waterfall/vip.png')"
                fit="contain" v-if="acData.vip">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
            <el-image style="width: 16px; height: 16px;margin-left: 6px;" 
              :src="require('../assets/img/personal/edit.png')" fit="contain"
              @click="personalEmit({ 
                num : 3 , 
              })"  
            >
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>

          <div style="font-size: 12px;color: #9A9AB7;line-height: 12px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;" >
            {{  acData.remark ? acData.remark : "This man has a lot of personality and leaves nothing behind." }} 
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="d-flex" style="text-align: left;align-items: center;flex:1;color: #FFFFFF;font-size: 13px;cursor: pointer;">
          <el-image style="width: 18px; height: 18px;margin-right: 6px;" 
            :src="require('../assets/img/personal/points.png')" fit="contain">
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
          point：{{  acData.points  }}
        </div>
        <div class="d-flex" style="justify-content: flex-end;align-items: center;flex:1;color: #9A9AB7;cursor: pointer;" @click="personalEmit({ 
          rechargeType : 1  ,
          num : 2 , 
          })">
          Buy
          <el-image style="width: 14px; height: 14px;margin-right: 6px;" 
            :src="require('../assets/img/personal/more.png')" fit="contain">
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
        </div>
      </div>
      <div class="member" :style="{'background-image': `url( ${  require('@/assets/img/personal/member.png')  } )`}">   
        <div class="date" v-if="acData && acData.endDate" style="left: 3px;">
          Due on {{  acData.endDate.split(" ")[0] }} 
        </div>
        <div class="date" style="cursor: pointer;left: 6px;" v-else @click="personalEmit({ 
          rechargeType : 8  ,
          num : 2 , 
          })">
          Learn more about benefits
        </div>
        <div class="recharge" v-if="acData && acData.endDate" @click="personalEmit({ 
          rechargeType : 8  ,
          num : 2 , 
          })">
          Renew now
        </div>
        <div class="recharge" v-else @click="personalEmit({ 
          rechargeType : 8  ,
          num : 2 , 
        })">
          Buy now
        </div>
      </div>
      <div class="btn_cont d-flex" style="justify-content: space-around;">
        <div class="item" @click="personalEmit({ 
          num : 7 , 
        })">
          <div style="text-align: center;">
            <el-image style="width: 36px; height: 36px;" 
              :src="require('../assets/img/personal/icon1.png')" fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>
          <div style="font-size: 12px;">Home</div>
        </div>
        <div class="item" @click="personalEmit({ 
          num : 8 , 
        })">
          <div style="text-align: center;">
            <el-image style="width: 36px; height: 36px;" 
              :src="require('../assets/img/personal/icon5.png')" fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>
          <div style="font-size: 12px;">task <br> center</div>
        </div>
        <div class="item" @click="personalEmit({ 
          num : 4 , 
        })">
          <div style="text-align: center;">
            <el-image style="width: 36px; height: 36px;" 
              :src="require('../assets/img/personal/icon2.png')" fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>
          <div style="font-size: 12px;">Message</div>
        </div>
        <div class="item" @click="personalEmit({ 
          num : 5 , 
        })">
          <div style="text-align: center;">
            <el-image style="width: 36px; height: 36px;" 
              :src="require('../assets/img/personal/icon3.png')" fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>
          <div style="font-size: 12px;">Recharge <br> Record</div>
        </div>
        <div class="item" @click="personalEmit({ 
          num : 6 , 
        })">
          <div style="text-align: center;">
            <el-image style="width: 36px; height: 36px;" 
              :src="require('../assets/img/personal/icon4.png')" fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>
          <div style="font-size: 12px;">Points <br> Record</div>
        </div>
      </div>
      <div class="quit_cont d-flex" @click="personalEmit( {
        num : 1 ,
      } )">
        <el-image style="width: 16px; height: 16px;margin-right: 3px;" class="icon"
          :src="require('../assets/img/personal/quit.png')" fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <el-image style="width: 16px; height: 16px;margin-right: 3px;" class="icon_act"
          :src="require('../assets/img/personal/quit_act.png')" fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        Log out
      </div>

    </div>



    <el-image style="width: 30px; height: 30px;border-radius: 50%;" slot="reference" 
      v-if="acData.photo"
      :src="acData.photo"
        fit="cover">
      <div slot="placeholder" class="image-slot"></div>
    </el-image>
    <el-image style="width: 30px; height: 30px;" slot="reference" 
      v-else
      :src="require('../assets/img/ava.png')"
        fit="cover">
      <div slot="placeholder" class="image-slot"></div>
    </el-image>
  </el-popover>
</template>

<script>
export default {
  props: {
    acData: {
      type: Object,
      default: {}
    },
  },
  methods : {
    personalEmit( status ){
      this.$emit('ListenPerson', status); 
    },
    popShow(){
      // this.$emit('popShow', true ); 
    }
  }
}
</script>

<style lang="scss" scoped>
  .member {
    width: 308px;
    height: 72px;
    background-size: contain;
    margin-top: 14px;
    margin-bottom: 24px;
    position: relative;
    background-size: cover;
    .date {
      font-size: 12px;
      color: #FFFFFF;
      position: absolute;
      bottom: 12px;
      left: 12px;
      transform: scale(0.9);
    }
  
    .recharge {
      width: 74px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      cursor: pointer;
      background: #FFFFFF;
      border-radius: 14px;
      position: absolute;
      top: 22px;
      right: 12px;
      font-size: 13px;
      font-weight: 500;
      color: #006FFF;
    }
  }

  .btn_cont {
    .item {
      font-size: 13px;
      font-weight: 400;
      color: #9A9AB7;
      text-align: center;
      cursor: pointer;
    }
  }

  .quit_cont {
    width: 308px;
    height: 38px;
    background: #252739;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #9A9AB7;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 28px;
  }
  .quit_cont:hover {
    color: #006FFF;
  }
  .quit_cont .icon{display:inline-block;}
  .quit_cont .icon_act{display:none;}
  .quit_cont:hover .icon{display:none;}
  .quit_cont:hover .icon_act{display:inline-block;}


</style>
