import Vue from 'vue'
import Vuex from 'vuex'

import imageImage from "./imageImage"
import textImage from './textImage'
import user from './user'
import message from './message'




Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    textImage ,
    user,
    imageImage,
    message
  }
})
