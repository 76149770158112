import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/lang'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/index.css'
import './styles/scrollbar.css'
import './styles/roboto.css'

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
import * as rules from "vee-validate/dist/rules";
import { Loading } from 'element-ui';

import { BootstrapVue, BootstrapVueIcons  } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueLoadmore from 'vuejs-loadmore';
import VueTypedJs from 'vue-typed-js'
import waterfall from 'vue-waterfall2'
import Vuebar from 'vuebar';
import wxlogin from 'vue-wxlogin';
import qrcodevue from 'qrcode.vue' ;
import VueCropper from 'vue-cropper'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import axios from 'axios'



// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("zh_CN", zh_CN);
// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('wxlogin', wxlogin);
Vue.component('qrcodevue', qrcodevue);


Vue.use(VueToast);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.config.productionTip = false
Vue.use(VueLoadmore ,  {
  lang: 'en-US'
});
Vue.use(VueTypedJs)
Vue.use(waterfall)
Vue.use(ElementUI);
Vue.use(Vuebar);
Vue.use(VueCropper)
Vue.use(Viewer)
Viewer.setDefaults({
// 工具栏按钮的控制
  toolbar: {
    zoomIn: 1, // 放大
    zoomOut: 1, // 缩小
    oneToOne: 1, // 100%大小
    reset: 1, // 还原
    prev: 1, // 上一张
    play: 1, // 全屏显示
    next: 1, // 下一张
    rotateLeft: 0, // 逆时针旋转
    rotateRight: 0, // 顺时针旋转
    flipHorizontal: false , // 水平翻转
    flipVertical: false // 垂直翻转
  }
})

Vue.prototype.formatWord = function (val) {
  if( val ){
    // let str = val.replace(/\n/g, '<br>') ;
    // str.replace( /\</g , '&lt;' )

    let str = val.replace( /\</g , '&lt;' ).replace(/\n/g, '<br>')

    return str
  } else {
    return ''
  }
}

Vue.prototype.$axios = axios ;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
