<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->

    <keep-alive :include="keepAlive">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
  export default {
    name: 'Layout',
    data() {
      return {
        // 需要缓存的组件名称
        keepAlive: []
      }
    },
    created() {
      this.checkRouteKeep(this.$route);
      this.$router.beforeEach((to, from, next) => {
        const { keepAlive, fromPath, componentName } = to.meta;
        if (keepAlive) {
          // 如果不是从详情页跳转到列表页，就清除列表页的缓存
          if (Array.isArray(fromPath) && fromPath.every(item => item != from.path)) {
            this.keepAlive = this.keepAlive.filter(item => item != componentName);
          }
          setTimeout(() => this.checkRouteKeep(to), 100);
        }
        next();
      });
    },
    methods: {
      checkRouteKeep(route) {
        const { keepAlive, componentName } = route.meta;
        if (keepAlive && this.keepAlive.every(item => item != componentName)) {
          this.keepAlive.push(componentName);
        }
      }
    }
  }
</script>


<style lang="scss">

html , body , #app {
  // height: 100%;
  background-color: #0B0B1F;
  color: #fff;
}

.row {
  margin-left : 0 !important;
  margin-right: 0 !important;;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #212529;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
