<template>
  <div class="cont">
    <Navbar :dialogLoginVisible.sync="loginFormShow" :needUpdate.sync="needUpdate" :needRecharge="needRecharge">
    </Navbar>
    <el-container style="height: 100%;">
      <NavbarLeft active_idx="/" v-if="shownav" style="position: fixed;left: 0"></NavbarLeft>
      <el-main style="height: 100%;padding-left: 252px;">
        <div class="top_cont">
          <el-image
            style="width: 60%; height: 100%;margin-right: 16px;flex-shrink: 0;background: #51aff8;border-radius: 18px;"
            :src="require('../assets/img/home/banner.png')" fit="contain" @click="ai_changeface_click">
            <div slot="placeholder" class="image-slot"></div>
          </el-image>

          <div style="width: 40%;position: relative;">
            <div
              style="background: #222337;height: 38px;width: 100%;display: flex;border-radius: 19px;padding: 0 12px;">
              <el-image style="width: 24px; height: 24px;margin-right: 5px;margin-top: 7px;"
                :src="require('../assets/img/home/trumpet.png')" fit="contain">
                <div slot="placeholder" class="image-slot"></div>
              </el-image>
              <el-carousel height="38px"
                style="width: calc(100% - 7px );overflow-y: hidden;height: 38px;overflow-x: hidden;line-height: 38px;"
                indicator-position="none" arrow="never" direction="vertical">
                <el-carousel-item v-for="(item, idx) in aiNewsList" :key="idx">
                  <div style="overflow:hidden; text-overflow:ellipsis; white-space:nowrap;font-size: 12px;"
                    @click="ainewsclick(item, idx)">
                    {{ item.title }}
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div style="height: calc(100% - 38px);width: 100%;display: flex;justify-content: space-between;margin-top: 16px;">
              <div class="citem1" @click="text_to_img_click">

              </div>
              <div class="citem2" @click="ai_changeface_click">

              </div>
            </div>
          </div>
        </div>

        <div class="waterfall_title">
          AI Square
        </div>

        <MyWaterfall @loginFormShowChange="loginFormShowChange"></MyWaterfall>




      </el-main>
    </el-container>

    <!-- <div class="container">
      <div class="home1 d-flex">
        <el-image style="width: 50px; height: 50px;margin-right: 16px;" 
        :src="require('../assets/img/logo.png')"
         fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <span style="margin-left:10px">Boser AI</span>
      </div>
      <div class="home2 d-flex">
        <div style="cursor: pointer;" class="active item">Chat </div>
        <div class="item" style="cursor: pointer;    margin-right: 22px;" @click="img_click">txt2img</div>
        <div class="item" style="cursor: pointer;" @click="img_to_img_click">img2img</div>
      </div>
      <div class="home3 d-flex">
        <div class="l d-flex">
          <el-image style="width: 24px; height: 24px;margin-left: 30px;margin-right: 18px;" 
          :src="require('../assets/img/search.png')"
           fit="contain">
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
        </div>
        <el-input v-model="text" :placeholder="placeholderText" class="input" @keyup.enter="search_click" ></el-input>
        <div class="search-btn d-flex"  @click="search_click">
          <el-image style="width: 32px; height: 32px;margin-right: 30px;" 
          :src="require('../assets/img/send.png')"
          fit="contain" >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
        </div>
      </div>
    </div>


 -->


    <LoginForm :dialogLoginVisible.sync="loginFormShow" v-if="loginFormShow"></LoginForm>
    <ElBacktop></ElBacktop>
    <NewRecharge :newRechargeShow.sync="newRechargeShow" v-if="newRechargeShow" @gotoRecharge="gotoRecharge">
    </NewRecharge>
    <AiNewsPop :ainewsVisible.sync="ainewsVisible" v-if="ainewsVisible" :aiNewsList="aiNewsList" :ainewsIdx="ainewsIdx"
      @changeaiidx="changeaiidx" @openAinews="openAinews"></AiNewsPop>

  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import NavbarLeft from "@/components/NavbarLeft.vue";

import common from '../utils/common'
import { square_page_req, img_list_req, save_square_req, square_detail } from "@/api/square";
import LoginForm from "@/components/LoginForm";
import NewRecharge from "@/components/NewRecharge";
import MyWaterfall from "@/components/MyWaterfall";
import ElBacktop from "@/components/ElBacktop"
import { getUUID, timeStampToHumanReadable } from "@/utils/bose";
import { user_invoke_req, user_account_req, event_log_req } from "@/api/user"
import md5 from 'js-md5';
import AiNewsPop from "@/components/AiNewsPop";

export default {
  name: "HomeView",
  components: {
    Navbar,
    LoginForm,
    MyWaterfall,
    ElBacktop,
    NewRecharge,
    NavbarLeft,
    AiNewsPop
  },
  data() {
    return {
      navdata: [],
      ac_data: {},//账户信息
      text: '',
      loginFormShow: false,
      placeholderText: "What nutrients does an egg contain?",
      placeList: [
        "What nutrients does an egg contain?",
        "How do people who have been single for a long time find a girlfriend?",
        "What do you think of 996?",
        "Why aren't there any particularly handsome guys chasing me?",
        "Should you reply to messages sent by your boss after get off work?",
        "How to keep chatting with someone you are romantically interested in?",
      ],
      needUpdate: 1,
      iconActive: false,
      timer: null,
      newRechargeShow: false, //新用户充值
      needRecharge: 0,
      shownav: true,
      aiNewsList: [],
      ainewsIdx: undefined,
      ainewsVisible: false,
    }
  },
  computed: {
    tokenVuex() {
      // 存储位置： store.state.{模块名,在store/index.js中module下的}.{state中定义的属性}
      return this.$store.state.user.token
    }
  },
  mounted() {
    this.getUserAccountInfo();
    this.getAiNews();
    this.navdata = this.$i18n.messages[this.$i18n.locale].getNavData;
    if (this.$route.params && this.$route.params.unlogin === true) {
      // this.loginFormShow = true ;
    }

    if (this.getQueryVariable("rec_id")) {
      this.get_rec_info(this.getQueryVariable("rec_id"));
    }
    if (this.getQueryVariable("share_url")) {
      this.gotoshare(decodeURIComponent(this.getQueryVariable("share_url")));
    }
    if (this.getQueryVariable("cc")) {
      localStorage.setItem("cc", this.getQueryVariable("cc"));
    }



    let _index = Math.floor((Math.random() * this.placeList.length));
    this.placeholderText = this.placeList[_index];
    this.timer = setInterval(() => {
      let _index = Math.floor((Math.random() * this.placeList.length));
      this.placeholderText = this.placeList[_index];
    }, 5000)


    // user_invoke_req({
    //   uid : getUUID() ,
    //   cnl : localStorage.getItem("cc") ? localStorage.getItem("cc") + '-pc': undefined , 
    // })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async getUserAccountInfo() {
      const { data, success } = await user_account_req()
      if (success) {
        if (data.ext) {
          data.ext = JSON.parse(data.ext)
          this.adultSwitch = data.ext && data.ext.adultSwitch ? data.ext.adultSwitch : false
        }
        this.ac_data = data

        setTimeout(() => {
          if (!localStorage.getItem("needRechargeShow") && this.tokenVuex) {
            localStorage.setItem("needRechargeShow", true)
            this.newRechargeShow = true;
          }
        }, 1000)
      }
      this.timestamp = Date.parse(new Date());
    },
    event_active_log(page) {

      let cnl = localStorage.getItem("cc") ? localStorage.getItem("cc") + '-pc' : 'none-pc'
      let uid = this.ac_data.uid ? this.ac_data.uid : getUUID();
      var ts = Date.parse(new Date());
      let detail_str = JSON.stringify({ page: page })
      let sign = md5('cnl' + cnl + 'detail' + detail_str + 'event' + "active" + 'ts' + ts + 'uid' + uid + 'yEBgmO69')
      event_log_req({
        // category : '' ,
        cnl: cnl,
        detail: detail_str,
        event: "active",
        ts: ts,
        uid: uid,
        sign: sign,
      })
    },
    googleLogin() {
      google.accounts.id.initialize({
        client_id: '619759958483-vimgi4eiok4hfijsq78fhdf9g18e9p89.apps.googleusercontent.com',
        callback: handleCredentialResponse
      });
      google.accounts.id.prompt();

      function handleCredentialResponse(res) {
        //console.log( res )
      }
    },


    search_click() {
      this.$store.commit('user/change_token');

      if (!this.tokenVuex) {
        this.loginFormShow = true;
        return;
      }
      this.$router.push({
        name: "aiQa", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        params: {
          text: this.text ? this.text : this.placeholderText,
          place: this.text ? false : true,
        }
      });
    },
    img_click() {
      this.$store.commit('user/change_token');

      if (!this.tokenVuex) {
        this.loginFormShow = true;
        return;
      }
      this.$router.push({
        name: "textImage", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      });
    },
    img_to_img_click() {
      this.$store.commit('user/change_token');
      if (!this.tokenVuex) {
        this.loginFormShow = true;
        return;
      }
      this.$router.push({
        name: "imageToImage", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      });
    },
    loginFormShowChange(data) {
      this.loginFormShow = data;
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
      }
      return (false);
    },
    get_rec_info(id) {
      square_detail(id).then(res => {
        let obj = JSON.parse(res.data.request);
        if (obj) {
          obj._id = id;
          obj.category = res.data.category;
          obj.url = res.data.url;
          obj.createdAt = res.data.createdAt;
          obj.router = "explore"
          this.$router.replace({
            name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
            params: obj
          });
        }
      })
    },
    gotoshare(url) {
      let obj = {}
      obj.router = "share"
      obj.url = url;
      this.$router.replace({
        name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        params: obj
      });
    },
    gotoRecharge() {
      this.newRechargeShow = false;
      this.needRecharge++;
    },
    text_to_img_click() {
      // this.$store.commit('user/change_token') ;
      // if( !this.tokenVuex ){
      //   this.loginFormShow = true ;
      //   return;
      // }
      this.$router.push({
        name: "textImage", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      });
    },
    img_to_img_click() {
      // this.$store.commit('user/change_token') ;
      // if( !this.tokenVuex ){
      //   this.loginFormShow = true ;
      //   return;
      // }
      this.$router.push({
        name: "imageToImage", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      });
    },
    ai_changeface_click() {
      this.$router.push({
        name: "ReplaceFace", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      });
    },
    getAiNews() {
      this.$axios.get('https://innews.infohubnews.app/v1/channels/news?cty=US&lang=en&offset=0&limit=100&ch=telegram&c=finance').then(res => {
        if (res.data.Result) {
          this.aiNewsList = res.data.Result;
        }
      })
    },
    ainewsclick(item, idx) {
      // window.open( item.link ,  "_blank" )  ;
      this.ainewsIdx = idx;
      this.ainewsVisible = true;
    },
    changeaiidx(idx) {
      this.ainewsIdx = idx;
    },
    openAinews(idx) {
      console.log(idx)
      this.ainewsVisible = false;
      this.$nextTick(() => {
        setTimeout(() => {
          this.ainewsIdx = idx;
          this.ainewsVisible = true;
        }, 30)
      })
    },
  },
  watch: {
    tokenVuex(newQuestion, oldQuestion) {
      // console.log( newQuestion )
      if (!newQuestion) {
        // this.loginFormShow = true ;
      } else {
        let page = undefined;
        if (this.$route.name == 'home') {
          page = 'home'
        } else if (this.$route.name == 'aiQa') {
          page = 'qa'
        } else if (this.$route.name == 'textImage') {
          page = 'textimage'
        } else if (this.$route.name == 'imageToImage') {
          page = 'imageimage'
        } else if (this.$route.name == 'explore') {
          page = 'explore'
        } else if (this.$route.name == 'Personal') {
          page = 'personal'
        }

        this.getUserAccountInfo().then(() => {
          if (page) {
            this.event_active_log(page)
          }
        })   //获取账户信息
      }
    },
    needUpdate(newQuestion) {
    }
  },

  activated() {

  },
  timeStampToHumanReadableMtd(time) {
    return timeStampToHumanReadable(time);
  },


};
</script>


<style lang="scss" scoped>
::v-deep .form-control {
  border: none;
}

::v-deep .form-control:focus {
  border-color: #fff;
  box-shadow: none
}

.cont {
  padding: 60px 20px 0 0;
  // height: calc(100% );
  box-sizing: border-box;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.icon {
  width: 40%;
  min-width: 70px;
  min-height: 70px;
  padding-bottom: 40%;
  margin: 0 auto;
}


.home1 {
  margin-top: 77px;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #fff;
}

.home2 {
  margin-top: 42px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;

  .item {
    box-sizing: border-box;
    height: 40px;
    width: 96px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
  }

  .active {
    background-color: #fff;
    color: #fff;
    font-weight: 500;
    height: 40px;
    width: 96px;
    background: #222337;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    margin-right: 32px;
  }
}

::v-deep .home3 .el-input__inner {
  background-color: #222337;
  border: none;
  color: #fff;
  padding-left: 0;
}

::v-deep .home3 .el-input__inner::placeholder {
  color: #49495A;
}

.home3 {
  width: 1000px;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 66px;
  border-radius: 33px;
  background-color: #222337;
  margin: 24px auto 20px;

  .l {
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 66px;
  }

  .input {
    background-color: #222337;
    color: #fff;
    padding: 0;
  }

  .input::placeholder {
    color: #49495A;
  }


  .search-btn {
    height: 66px;
    width: 92px;
    align-items: center;
    justify-content: center;
  }
}

.waterfall_title {
  font-size: 24px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 33px;
  margin-top: 43px;
  margin-bottom: 16px;
}

.top_cont {
  width: 100%;
  height: 312px;
  display: flex;

  .banner {
    //background-image:  url("../assets/img/home/banner.png") ;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
  }

  .news {
    flex: 1;
    height: 100%;
    background: linear-gradient(180deg, #4D5063 0%, #0C112A 100%);
    border-radius: 10px;
    overflow-y: scroll;
    padding: 12px;
    box-sizing: border-box;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 15px;
  }

  .item {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    cursor: pointer;

    .left {
      position: absolute;
      width: 10px;
      height: 10px;
      background: #72E3F5;
      border-radius: 50%;
      left: 0;
      top: 6px;
    }

    .right {
      padding-left: 18px;
    }

    font-weight: 400;
    font-size: 15px;
    color: #9A9AB7;
  }

  .citem1 {
    width: 49%;
    height: calc(100% - 16px);
    flex-shrink: 0;
    background-image: url("../assets/img/home/bg1.png");
    background-position: top 0% left 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .citem2 {
    width: 49%;
    height: calc(100% - 16px);
    flex-shrink: 0;
    background-image: url("../assets/img/home/bg2.png");
    background-position: top 0% left 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;

  }
}
</style>