<template>
  <el-dialog class="dialog" :visible.sync="dialogPayStatusShow" :before-close="handleClose" :show-close="false"
    append-to-body width="864px">
    <div class="cont" v-if="payStatus == 'success' ">
      <div class="title d-flex">
        <span style="margin: 0 7px;">Payment successful</span>
        <el-image style="width: 24px; height: 24px;cursor: pointer;position: absolute;right: -20px;top: -6px;"
          @click="handleClose()" :src="require('../assets/img/recharge/close.png')" fit="contain">
          <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
        </el-image>
      </div>

      <div style="text-align: center;margin-top: 160px;">
        <el-image style="width: 88px; height: 88px;"
          :src="require('../assets/img/feedback/success.png')" fit="contain">
          <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
        </el-image>
      </div>
      <div style="text-align: center;margin-top: 30px;font-size: 15px;color: #CACAD4;">
        Congratulations on your successful payment! Go to the homepage to find more inspiration.
      </div>
    </div>
    <div class="cont" v-else-if="payStatus == 'cancel'">
      <div class="title d-flex">
        <span style="margin: 0 7px;">Payment failed</span>
        <el-image style="width: 24px; height: 24px;cursor: pointer;position: absolute;right: -20px;top: -6px;"
          @click="handleClose()" :src="require('../assets/img/recharge/close.png')" fit="contain">
          <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
        </el-image>
      </div>


      <div style="text-align: center;margin-top: 160px;">
        <el-image style="width: 88px; height: 88px;"
          :src="require('../assets/img/feedback/fail.png')" fit="contain">
          <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
        </el-image>
      </div>
      <div style="text-align: center;margin-top: 30px;font-size: 15px;color: #CACAD4;">
        There is a problem with the payment. Please try to pay again
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getBaseUrl, getToken } from "@/utils/bose";
import { guestbookSaveReq , guestbook_page_req } from "@/api/feedback"



export default {
  props: {
    dialogPayStatusShow: {
      type: Boolean,
      default: false,
    },
    payStatus : {
      type: String,
      default: "success",
    }
  },
  data() {
    return {
      type: "1",

    }
  },
  methods: {
    handleClose(done) {
      this.$emit('update:dialogPayStatusShow', false) // 传递更新数值
    },
  }
}
</script>

<style lang="scss" scoped>  
::v-deep .el-dialog {
    min-width: 864px;
    // position: relative;
    // background-color: palegreen;
    background-color: #1B1D2E !important;
    box-shadow: none;

    display: flex;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    border-radius: 20px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;

      .cont {
        width: 864px;
        height: 653px;
        padding: 20px 34px;
        box-sizing: border-box;

        .title {
          height: 30px;
          line-height: 30px;
          width: 100%;
          position: relative;
          justify-content: center;
          align-items: center;
          font-size: 21px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 29px;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        }

        .sub_title {
          width: 100%;
          font-size: 15px;
          font-weight: 400;
          color: #CACAD4;
          line-height: 21px;
          margin-top: 9px;
          margin-bottom: 14px;
          text-align: center;
        }

        .subject {
          margin-bottom: 13px;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
        }

        .type {
          justify-content: space-between;
          margin-bottom: 22px;

          .type_item {
            width: 190px;
            height: 40px;
            line-height: 38px;
            background: rgba(155, 184, 220, 0.08);
            border-radius: 10px;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            color: #CACAD4;
            cursor: pointer;
            border: 1px solid rgba(155, 184, 220, 0.08);
            box-sizing: border-box;
          }

          .type_item.active {
            background: rgba(0, 111, 255, 0.15);
            border: 1px solid #006FFF;
            color: #006FFF;
          }
        }

        .text-cont {
          box-sizing: border-box;
          border: none;
          max-height: 124px;
          padding: 8px 0;
          min-height: 66px;
          background: #111225;
          border-radius: 10px;

          textarea {
            border: none;
            resize: none;
            cursor: pointer;
          }
        }

        .upload_item {
          width: 70px;
          height: 70px;
          border-radius: 10px;

        }
      }
    }
  }




  ::v-deep .el-textarea.is-disabled .el-textarea__inner {
    background: #111225;
  }

  ::v-deep .el-textarea__inner {
    background: #111225;
    border: none;
    color: #fff;

    &::placeholder {
      color: #666;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers 适配谷歌 */
      color: #666;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 适配火狐 */
      color: #666;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ 适配火狐 */
      color: #666;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10+  适配ie*/
      color: #666;
    }
  }




  ::v-deep .avatar-uploader .el-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    background: rgba(155, 184, 220, 0.08);
    border-radius: 10px;
    color: #CACAD4;
    margin-right: 12px;
  }

 .replyListCont {
  .replyItem {
    padding: 12px 16px;
    box-sizing: border-box ;
    background: #111225;
    margin-bottom: 20px;
    .date {
      font-size: 14px;
      font-weight: 500;
      color: #9696A2;
      line-height: 20px;
    }
    .line {
      font-size: 14px;
      font-weight: 400;
      color: #CACAD4;
      line-height: 20px;
    }
    .answer {
      background: #1B1D2E;
      border-radius: 10px;
      padding: 10px 14px;
      font-size: 13px;
      font-weight: 400;
      color: #9696A2;
      line-height: 18px;
      // background: #111225;
      margin-top: 16px;
      background: #1B1D2E;

    }
    .icon {
      background: #F5B109
    }
    .activedIcon {
      background : #12D107
    }
  }
 } 
</style>