<template>
  <!-- <b-nav vertical class="cont">
    <b-nav-item to="/" class="nav-line" >
      <span> {{ $t("message.homeTitleLeft") }} </span>
    </b-nav-item>
    <b-nav-item v-for="(item,index) in navdata"  :key="index" :to="item.route" class="nav-line"
      :class="{ 'nav_active': item.route == path }"
    > 
      <span style="text-align:left"> {{ item.title }}  </span>
    </b-nav-item>
  </b-nav> -->

  <el-aside width="220px" style="height: 100%;background-color: #111225 ; padding: 14px 16px; box-sizing: border-box;position: relative;">
    <el-menu
      class="el-menu-vertical-demo"
      background-color="#111225"
      text-color="#fff"
      active-text-color="rgba(0,111,255,0.3);"
      :default-active="active_idx"
      router
      >
      <el-menu-item index="/">
        <el-image style="width: 20px; height: 20px;margin-right: 8px;" 
        :src="require('../assets/img/nav/home.png')"
          fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image><span slot="title">Home</span>
      </el-menu-item>

      <el-menu-item index="/aiphoto">
        <el-image style="width: 20px; height: 20px;margin-right: 8px;" 
        :src="require('../assets/img/nav/aiphoto.png')"
           fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image><span slot="title">AI Photography</span>
      </el-menu-item>
      <el-menu-item index="/replaceFace">
        <el-image style="width: 20px; height: 20px;margin-right: 8px;" 
        :src="require('../assets/img/nav/replaceface.png')"
           fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image><span slot="title">AI Face Swap</span>
      </el-menu-item>
      <el-menu-item index="/HDrestoration">
        <el-image style="width: 20px; height: 20px;margin-right: 8px;" 
        :src="require('../assets/img/nav/HDrestoration.png')"
           fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image><span slot="title">HD Restoration</span>
      </el-menu-item>
      <el-menu-item index="/HDzoom">
        <el-image style="width: 20px; height: 20px;margin-right: 8px;" 
        :src="require('../assets/img/nav/HDzoom.png')"
           fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image><span slot="title">HD Magnification</span>
      </el-menu-item>

      <el-menu-item index="/AIfilter">
        <el-image style="width: 20px; height: 20px;margin-right: 8px;" 
        :src="require('../assets/img/nav/AIfilter.png')"
           fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image><span slot="title">AI Filter</span>
      </el-menu-item>


      <el-menu-item index="/ai-qa">
        <el-image style="width: 20px; height: 20px;margin-right: 8px;"
        :src="require('../assets/img/nav/wenda.png')"
           fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image><span slot="title">Chat</span>
      </el-menu-item>
      <el-menu-item index="/text-image">
        <el-image style="width: 20px; height: 20px;margin-right: 8px;" 
        :src="require('../assets/img/nav/wenshengtu.png')"
           fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image><span slot="title">txt2img</span>
      </el-menu-item>
      <el-menu-item index="/image-image">
        <el-image style="width: 20px; height: 20px;margin-right: 8px;" 
        :src="require('../assets/img/nav/tushengtu.png')"
           fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image><span slot="title">img2img</span>
      </el-menu-item>
    </el-menu>

    <div class="addChat d-flex" style="align-items: center;" @click="addChatClick" v-if="path == '/ai-qa'">
      <el-image style="width: 20px; height: 20px;margin-right: 8px;"
        :src="require('../assets/img/nav/add.png')"
          fit="contain">
        <div slot="placeholder" class="image-slot"></div>
      </el-image>New chat
    </div>
  </el-aside>



</template>

<script>
import common from '../utils/common'

export default {
  props : {
    active_idx: {
      type: String ,
      default: "/"
    },
  }, 
  data () {
    return {
      navdata : [] ,
      path : undefined , 
    }
  } , 
  mounted () {
    //this.navdata = common.getNavData()
    this.navdata = this.$i18n.messages[this.$i18n.locale].getNavData   ;
    this.path = this.$route.path
  } ,
  methods : {
    addChatClick(){
      this.$emit('addChat', true );
    } ,
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-menu-item.is-active {
  background-color: rgba(0,111,255,0.3) !important;
  border-radius: 10px;
  color: #fff;
}
::v-deep .el-menu {
  border-right: 1px solid rgb(17, 18, 37) ;
}


::v-deep .nav-link {
  padding-left: 3px !important;
}
.nav_active  a{
  color: #fff;
}


.cont {
  position: sticky;
  top: 180px;
  z-index: 1000;
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  flex-wrap: nowrap;
  color: #fff;
}
.navbar {
  margin-left: 0;
  margin-right:  0;
}

.home-icon {
  color: #000;
}

a {
  text-decoration: none;
}
 
.router-link-active {
  text-decoration: none;
}


.nav-line  > a{
  display: flex;
  align-items: center;
  justify-content: flex-start;

}
.nav-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 15px;
}

.addChat {
  position: absolute; 
  left: 15px;
  bottom: 38px;
  width: 188px;
  height: 52px;
  background: #222337;
  border-radius: 10px;
  padding: 16px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
}
</style>