import request from '@/utils/request'
import axios from 'axios'

export function rechargeOrderReq(data) {
  return request({
    url:  '/recharge/order',
    method: 'post',
    data: data,
  })
}

//rechargeStatusReq
export function rechargeStatusReq(data) {
  return request({
    url:  '/recharge/status',
    method: 'post',
    data: data,
  })
}

export function rechargePriceListReq(data) {
  return request({
    url:  '/price/list',
    method: 'post',
    data: data,
  })
}

export function rechargeStripOrderReq(data) {
  return request({
    url:  '/recharge/stripe-order',
    method: 'post',
    data: data,
  })
}