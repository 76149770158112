import { render, staticRenderFns } from "./MyWaterfall.vue?vue&type=template&id=749fa20d&scoped=true&"
import script from "./MyWaterfall.vue?vue&type=script&lang=js&"
export * from "./MyWaterfall.vue?vue&type=script&lang=js&"
import style0 from "./MyWaterfall.vue?vue&type=style&index=0&id=749fa20d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749fa20d",
  null
  
)

export default component.exports