import request from '@/utils/request'
import axios from 'axios'

// let api = process.env.VUE_APP_API_BASE_URL
let api = ""


export function square_page_req(data) {
  return request({
    url:  '/square/page',
    method: 'post',
    data: data,
  })
}

export function img_list_req(data) {
  return request({
    url:  '/img/list',
    method: 'post',
    data: data,
  })
}

export function save_square_req(data) {
  return request({
    url:  '/square/save',
    method: 'post',
    data: data,
  })
}


export function square_detail( id ){
  return request({
    url:  '/square/detail/' + id ,
    method: 'get',
    // query: data,
  })
}

export function like_edit_req(data) {
  return request({
    url:  '/like/edit',
    method: 'post',
    data: data,
  })
}

export function painting_download_req(query) {
  return request({
    url:  '/painting/download',
    method: 'GET',
    params: query
  })
}