<template>
    <el-dialog
      class="dialog"
      :visible.sync="dialogRechargeVisible"
      :before-close="handleClose"
      :show-close="false"
      append-to-body
      width="864px"
    >
      <div class="cont d-flex">
        <div class="l" style="background: #111225;overflow-wrap: break-word;word-break: break-word;">
          <el-image style="width: 173px; height: 129px;cursor: pointer;margin: 40px auto 5px;display: block;"
            :src="require('../assets/img/recharge/member.png')"
            fit="contain" >
            <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);">
              </div>
          </el-image>
          <div style="height: 36px;text-align: center;margin-bottom: 38px;">
            <el-image style="width: 194px; height: 25px;"
              :src="require('../assets/img/recharge/img2.png')"
              fit="contain" >
              <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);">
              </div>
            </el-image>
          </div>
          <div class="d-flex" style="text-align: center;font-size: 13px;font-weight: 500;color: #FFFFFF;line-height: 16px;justify-content: center;margin-bottom: 30px;">
            <div style="width: 72px;">Equity</div>
            <div style="width: 72px;">Ordinary</div>
            <div style="width: 72px;">Recharge</div>
            <div style="width: 72px;">Member</div>
          </div>
          <div class="d-flex" style="text-align: center;font-size: 12px;font-weight: 400;color: #FFFFFF;line-height: 16px;justify-content: center;;margin-bottom: 15px;">
            <div style="width: 72px;">Multi-model switching</div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image>
            </div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
            <div style="width: 72px;">              
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
          </div>
          <div class="d-flex" style="text-align: center;font-size: 12px;font-weight: 400;color: #FFFFFF;line-height: 16px;justify-content: center;;margin-bottom: 15px;">
            <div style="width: 72px;">Top Speed</div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/hasnot.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image>
            </div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
            <div style="width: 72px;">              
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
          </div>
          <div class="d-flex" style="text-align: center;font-size: 12px;font-weight: 400;color: #FFFFFF;line-height: 16px;justify-content: center;;margin-bottom: 15px;">
            <div style="width: 72px;">infinite drawing</div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/hasnot.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image>
            </div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/hasnot.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
            <div style="width: 72px;">              
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
          </div>
          <div class="d-flex" style="text-align: center;font-size: 12px;font-weight: 400;color: #FFFFFF;line-height: 16px;justify-content: center;;margin-bottom: 15px;">
            <div style="width: 72px;">Batch Drawing</div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image>
            </div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
            <div style="width: 72px;">              
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
          </div>
          <div class="d-flex" style="text-align: center;font-size: 12px;font-weight: 400;color: #FFFFFF;line-height: 16px;justify-content: center;;margin-bottom: 15px;">
            <div style="width: 72px;">Unlimited Q&A</div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/hasnot.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image>
            </div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
            <div style="width: 72px;">              
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
          </div>
          <div class="d-flex" style="text-align: center;font-size: 12px;font-weight: 400;color: #FFFFFF;line-height: 16px;justify-content: center;;margin-bottom: 38px;">
            <div style="width: 72px;">Advanced Settings</div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/hasnot.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image>
            </div>
            <div style="width: 72px;">
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
            <div style="width: 72px;">              
              <el-image style="width: 16px; height: 16px;margin-top:6px"
                :src="require('../assets/img/recharge/has.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image></div>
          </div>
          <div style="height: 18px;text-align: left;">
            <el-image style="width: 216px; height: 14px;margin-left: 27px;"
              :src="require('../assets/img/recharge/img1.png')"
              fit="contain" >
              <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
            </el-image>
            <el-image style="width: 16px; height: 14px;margin-left: 1px;"
              :src="require('../assets/img/recharge/img3.png')"
              fit="contain" >
              <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
            </el-image>
          </div>


        </div>
        <div class="r">
          <div class="title d-flex" style="margin-bottom: 13px;">
            <div class="title_l">Point</div>
            <div class="title_r">
              <el-image style="width: 24px; height: 24px;cursor: pointer;"
                @click="handleClose()"
                :src="require('../assets/img/recharge/close.png')"
                fit="contain" >
                <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
              </el-image>
            </div>
          </div>

          <div class="out" style="position: relative;" @mouseenter="mouseEnterOut(1)" @mouseleave="mouseLeaveOut(1)">
            <i class="el-icon-arrow-left el-icon-arrow" :style="active1" style="left: -3px;top: 60px;"
              @mouseenter="mouseEnter(1)" @mouseleave="mouseLeave(1)" @click="clickLeft(1)" v-if="icon1show && rechargeDataPoint.length > 4"></i>
            <i class="el-icon-arrow-right el-icon-arrow" :style="active2" style="right: -3px;top: 60px;"
              @mouseenter="mouseEnter(2)" @mouseleave="mouseLeave(2)" @click="clickRight(1)"  v-if="icon1show && rechargeDataPoint.length > 4"></i>

            <div class="d-flex rechargeItemLine" ref="idSwiper1Img" style="position: relative;">
              <template v-for="(item, idx ) in rechargeDataPoint">
                <div class="rechargeItem" :class="{ active: item.active }" @click="toggle(item.id , 'point')"
                  style="flex-shrink: 0;">
                  <el-image class="_icon" :src="item.icon" v-if="item.icon"></el-image>
                  <div class="name">{{ item.points }}ponits</div>
                  <div class="price d-flex"
                    style="align-items: flex-end;justify-content: center;flex-wrap: nowrap;overflow: hidden;max-height: 44px;">
                    <span
                      style="height:16px;line-height: 13px;font-size: 13px;font-weight: 500;color: #FFFFFF;">￥</span>{{
                        (item.nowPrice / 100) }}</div>
                  <div class="price_o"> <span style="text-decoration: line-through;">￥{{ (item.price / 100) }}
                    </span></div>
                    <div class="desc" v-if="item.onlyOne != 1"> only{{ ((item.nowPrice / 100)/item.points).toFixed(2) < 0.01 ? 0.01 : ((item.nowPrice / 100)/item.points).toFixed(2)   }}/point</div>
                    <div class="desc" v-if="item.onlyOne == 1">one time</div>
                </div>
              </template>
            </div>
          </div>

          <div class="title d-flex" style="margin-bottom: 13px;margin-top: 21px;">
            <div class="title_l">Member</div>
          </div>

          <div class="out" style="position: relative;" @mouseenter="mouseEnterOut(2)" @mouseleave="mouseLeaveOut(2)">
            <i class="el-icon-arrow-left el-icon-arrow" :style="active1" style="left: -3px;top: 60px;"
              @mouseenter="mouseEnter(1)" @mouseleave="mouseLeave(1)" @click="clickLeft(2)" v-if="icon2show && rechargeDataMember.length > 4 "></i>
            <i class="el-icon-arrow-right el-icon-arrow" :style="active2" style="right: -3px;top: 60px;"
              @mouseenter="mouseEnter(2)" @mouseleave="mouseLeave(2)" @click="clickRight(2)"  v-if="icon2show && rechargeDataMember.length > 4"></i>
              
            <div class="d-flex rechargeItemLine" ref="idSwiper2Img">
              <template v-for="(item, idx ) in rechargeDataMember">
                <div class="rechargeItem" :class="{ active: item.active }" @click="toggle(item.id , 'member')"
                  style="flex-shrink: 0;">
                  <el-image class="_icon" :src="item.icon" v-if="item.icon"></el-image>
                  <div class="name">{{ item.points }}{{ item.unit == 1 ? 'day' : 'month' }}</div>
                  <div class="price d-flex"
                    style="align-items: flex-end;justify-content: center;flex-wrap: nowrap;overflow: hidden;max-height: 44px;">
                    <span
                      style="height:16px;line-height: 13px;font-size: 13px;font-weight: 500;color: #FFFFFF;">￥</span>{{
                        (item.nowPrice / 100) }}</div>
                  <div class="price_o"> <span style="text-decoration: line-through;">￥{{ (item.price / 100) }}
                    </span></div>
                  <div class="desc" v-if="item.onlyOne != 1 && item.unit == 1">only{{ (((item.nowPrice / 100)/item.points)).toFixed(2) < 0.01 ? 0.01 :  (((item.nowPrice / 100)/item.points)).toFixed(2) }}/day</div>
                  <div class="desc" v-if="item.onlyOne != 1 && item.unit == 2">only{{ (((item.nowPrice / 100)/(item.points * 30))).toFixed(2) < 0.01 ? 0.01 :  (((item.nowPrice / 100)/(item.points * 30) )).toFixed(2) }}/day</div>
                  <div class="desc" v-if="item.onlyOne == 1">one time</div>
                </div>
              </template>
            </div>
          </div>

          <div style="font-size: 12px;color: #FFFFFF;line-height: 20px;margin-top: 18px;overflow-wrap: break-word;word-break: break-word;" >
            Note: Virtual products are not eligible for refund, and the final interpretation rights belong to Boser AI.
          </div>
          
          <b-button block variant="primary" pill style="height: 40px;line-height: 40px;margin-top: 20px;" @click="createOrder"> To pay </b-button>


          <div class="d-flex code" >
            <!-- <div class="l" style="width: 144px;margin-right: 8px">
              <div style="padding: 10px; width: 144px;height: 144px;;border-radius: 8px;background-color: #fff;position: relative;" v-if="payUrl">
                <div class="cover_code d-flex" style="justify-content: center;align-items: center;" v-if="!agree">
                  请勾选：同意《充值协议》和《隐私协议》
                </div>
                <qrcodevue :value="payUrl" :size="124"></qrcodevue>
              </div>
            </div> -->
            <div class="code_r">
              <!-- <div class="d-flex" style="align-items: flex-end;">
                <span style="font-size: 16px;font-weight: 500;color: #FFFFFF;line-height: 22px;">扫码支付：</span>
                <span style="font-size: 16px;font-weight: 500;color: #EF4545;line-height: 22px;">￥</span>
                <span style="font-size: 32px;font-weight: 500;color: #EF4545;line-height: 32px;">{{ payPrice }}</span>
              </div>

              <div class="d-flex" style="height: 24px;align-items: center;margin-top: 12px;">
                <el-image style="width: 24px; height: 24px;"
                  :src="require('../assets/img/login/wechat.png')"
                  fit="contain" >
                </el-image>
                <span style=" margin-left: 4px;font-size: 12px;color: #FFFFFF;">使用微信扫码支付</span>
              </div>
              <div style="font-size: 12px;color: #FFFFFF;line-height: 17px;margin-top: 9px;">
                提示：虚拟产品不支持退款，最终解释权归上海博色所有
              </div> -->
              <div style="margin-top: 10px; align-items: center;" class="d-flex">
                <el-image style="width: 17px; height: 17px;margin-right: 6px;"
                  :src="require('../assets/img/recharge/checked.png')"
                  fit="contain"  v-show="agree" @click="agree = false ">
                </el-image>
                <el-image style="width: 17px; height: 17px;margin-right: 6px;"
                  :src="require('../assets/img/recharge/unchecked.png')"
                  fit="contain"  v-show="!agree" @click="agree = true ">
                </el-image>
                Agree to the<span style="color: #006FFF;cursor: pointer;" @click="jump('https://canvascraft.art/rechargeAgreement.html')">"Recharge Agreement"</span> <span style="margin: 0 5px;">and</span>   <span style="color: #006FFF;cursor: pointer;" @click="jump('https://canvascraft.art/UserprivacyAgreement.html')">"Privacy Agreement"</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </el-dialog>
</template>

<script>
import {user_account_req } from "@/api/user";
import {rechargeOrderReq , rechargeStatusReq  , rechargeStripOrderReq , rechargePriceListReq  } from "@/api/recharge"

export default {
    props : {
      dialogRechargeVisible: {
        type: Boolean ,
        default: false , 
      },
    }, 
    data(){
      return {
        rechargeDataMember: [],
        rechargeDataPoint: [],

       agree : true  , 
       getCode : false  ,
       payUrl : undefined , 
       rechargeNo : undefined,
       isQuery : false , //查询中
       payPrice : 0 ,
       rechargeType: undefined,
        _type: undefined,

        active1: '',
        active2: '',
        icon1show: false,
        icon2show: false,
      }
    },    
    methods: {
      handleClose(done) {
        clearInterval(this.timer);
        this.$emit('update:dialogRechargeVisible',  false ) // 传递更新数值
      } ,
      jump( url ){
        window.open(url,"_blank")
      } ,
      toggle(id, rechargeType) {        
        let _this = this ; 
        // if(  this.getCode != true  ){
        //   return;
        // }
        // console.log( type )  点击的type
        _this._type = id;
        this.rechargeDataMember.forEach(element => {
          element.active = false;
        });
        this.rechargeDataPoint.forEach(element => {
          element.active = false;
        });

        if (rechargeType == 'point') {
          this.rechargeDataPoint.forEach(element => {
            if (element.id == _this._type) {
              element.active = true;
              // console.log( element )
              _this.payPrice = (element.nowPrice / 100).toFixed(2)
            }
          });
        } else if (rechargeType == 'member') {
          this.rechargeDataMember.forEach(element => {
            if (element.id == _this._type) {
              element.active = true;
              // console.log( element )
              _this.payPrice = (element.nowPrice / 100).toFixed(2)
            }
          });
        }


        // this.getRechargeOrder( type );


      } ,
      getRechargeOrder( _type ){
        clearInterval(this.timer);
        this.getCode = false  ;
        rechargeOrderReq({
          type : _type , 
          os : 1 
        }).then( res => {
          
          this.payUrl = res.data.payUrl ;
          this.rechargeNo = res.data.rechargeNo ; 
          this.startTaskStatus( );
        }).catch( e =>{} ).finally( code => {
          this.getCode = true        
        })
      } ,
      getRechargePriceList() {
        let _this = this;
        rechargePriceListReq().then(res => {
          res.data.forEach(val => {
            val.active = false;
            if (val.category == 1) {
              this.rechargeDataMember.push(val)
            } else if (val.category == 2) {
              this.rechargeDataPoint.push(val)
            }
          })
          this.rechargeDataMember[0].active = true;

          _this.getCode = true;
          _this.toggle(this.rechargeDataMember[0].id, 'member');

          // this.getRechargeOrder(this.rechargeDataMember[0].id);
        })
      },
      startTaskStatus() {
        this.timer = setInterval(() => {
          if (this.isQuery == true) return;
          this.isQuery = true;
          rechargeStatusReq({
            os : 1 , 
            rechargeNo : this.rechargeNo
          }).then( res => {
            if( res.data.status == 1 ){
              this.$toast.open({
                message: "Recharge successful!",
                position: 'top',
                duration : 3000,
                queue : true   , 
                type: 'success',
              })
              this.handleClose()
              clearInterval(this.timer);
            }
          }).catch((err) => {
            clearInterval(this.timer);
          }).finally((res) => {
            this.isQuery = false;
          })
        }, 1000)
      },
      createOrder(){
        // rechargeDataMember: [],
        // rechargeDataPoint: [],






        this.rechargeDataMember.forEach(element => {
          if( element.active ){
            const loading = this.$loading({
              lock: true,
              text: 'loading...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)' ,
              customClass : "my_loading" 
            });


            rechargeStripOrderReq({
              type : element.id 
            }).then( res => {
              loading.close();
              window.location.href = res.data.payUrl
            })
          }
          
        });

        this.rechargeDataPoint.forEach(element => {
          if( element.active ){
            const loading = this.$loading({
              lock: true,
              text: 'loading...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)' ,
              customClass : "my_loading" 
            });


            rechargeStripOrderReq({
              type : element.id 
            }).then( res => {
              loading.close();
              window.location.href = res.data.payUrl
            })
          }
          
        });
      },
      clickLeft(num) {
        if (this.$refs['idSwiper' + num + 'Img'].scrollLeft > 105) {
          this.$refs['idSwiper' + num + 'Img'].scrollLeft -= 105
        } else {
          this.$refs['idSwiper' + num + 'Img'].scrollLeft = 0
        }
      },
      clickRight(num) {
        this.$refs['idSwiper' + num + 'Img'].scrollLeft += 105
      },
      mouseEnterOut(num) {
        this['icon' + num + 'show'] = true;
      },
      mouseLeaveOut(num) {
        this['icon' + num + 'show'] = false;
      },
      mouseEnter: function (num) {
        this['active' + num] = 'color: rgb(0, 111, 255)';
      },
      mouseLeave: function (num) {
        this['active' + num] = '';
      },

    } ,
    mounted(){
      let _this = this ;
      this.getRechargePriceList();

    }
}
</script>

<style lang="scss" scoped>  


::v-deep .el-dialog {
  min-width: 864px;
  // position: relative;
  // background-color: palegreen;
  background-color:#1B1D2E  !important;
  box-shadow : none ;

  display: flex;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  border-radius: 20px ;

  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;    
    .cont {
      width: 864px;
      height: 644px;
      .l  {
        width: 336px;
        border-radius: 20px 0px 0px 20px;
      }
      .r  {
        width: 528px;
        box-sizing: border-box;
        padding: 14px 0;
        .title {
          height: 24px;
          line-height: 24px;
          padding: 0 14px;
          .title_l {
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            flex: 1;
          }
          .title_r {
            text-align: right;
            flex: 1;
          }
        }
        .rechargeItem {
          width: 107px;
          height: 144px;
          background: #1A1B30;
          border-radius: 8px;
          border: 1px solid #282947;
          position: relative;
          text-align: center;
          margin-right: 12px;
          cursor: pointer;
          &.active {
            border: 1px solid #006FFF;
            background: rgba(0, 111, 255, 0.1);
            .desc {
              background-color: #006FFF;
            }
          }
          ._icon {
            width: 60px;
            height: 16px;
            position: absolute;
            right: 0;
            top: 0;
          }

          .name {
            height: 21px;
            font-size: 15px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 21px;
            margin-top: 24px;
          }
          .price {
            font-size: 34px;
            color: #FFFFFF;
            line-height: 36px;
            margin-top: 8px;
            font-weight: 500;
          }
          .price_o {
            font-size: 13px;
            font-weight: 400;
            color: #999999;
            line-height: 13px;
            margin-top: 5px;
          }
          .desc {
            position: absolute ;
            bottom: -1px;
            left: 0;
            width: 105px;
            height: 25px;
            background: #282B43;
            border-radius: 0px 0px 8px 8px;

            font-size: 11px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
          }

        }
        .code {
          height: 144px;
          margin-top: 20px;
          padding: 0 14px;
        }
      }



    }
  }
}

.cover_code {
  background-color:rgba(0,0,0,0.2);
  backdrop-filter : blur(8px);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 18px;
  box-sizing: border-box;
  font-size: 11px;
  font-weight: 500;
  color: #333333;
}
.out {
    width: 100%;
    padding: 0 14px;

    .rechargeItemLine::-webkit-scrollbar {
      display: none;
      /* Chrome Safari */
    }

    .rechargeItemLine {

      scrollbar-width: none;
      /* firefox */
      -ms-overflow-style: none;
      /* IE 10+ */
      flex-wrap: nowrap;
      overflow-x: scroll;
    }

    .el-icon-arrow {
      color: #fff;
      font-size: 20px;
      position: absolute;
    }
  }
</style>