import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: () => import( '../views/Login')
    component: HomeView ,
    // component: () => import("../views/Videodownload")
    meta : {
      keepAlive : true , 
      componentName: 'HomeView',
      fromPath: ['/explore' ]
    }
  },
  {
    path: '/ai-qa' , 
    name : 'aiQa' , 
    component: () => import( '../views/aiQa')
  },
  {
    path: '/text-image' ,
    name : 'textImage' ,
    component: () => import( '../views/textImage')
  },
  {
    path: '/image-image' ,
    name : 'imageToImage' ,
    component: () => import( '../views/imageToImage')
  },
  {
    path: '/test' , 
    name : 'test' ,
    component: () => import( '../views/test')

  },
  {
    path: '/login' , 
    name : 'login' ,
    component: () => import( '../views/Login')
  },
  {
    path: "/square" ,
    name : 'square' ,
    component: () => import( '../views/Square')
  },
  {
    path: "/square_config" ,
    name : 'square_config' ,
    component: () => import( '../views/SquareConfig')
  },
  {
    path: "/explore" ,
    name : 'explore' ,
    component: () => import( '../views/Explore')
  },
  {
    path: "/share" ,
    name: "share" , 
    component: () => import( '../views/Share')
  },
  {
    path: "/personal" ,
    name: "Personal" , 
    component: () => import( '../views/Personal')
  },
  {
    path: "/taskCenter" ,
    name: "TaskCenter" ,
    component: () => import("../views/TaskCenter"),
  },
  {
    path: "/myGroup" ,
    name: "MyGroup" , 
    component: () => import("../views/MyGroup") ,
    meta : {
      keepAlive : true , 
      componentName: 'MyGroup',
      fromPath: ['/explore' ]
    }

  },
  {
    path: "/page/pay/success" ,
    name: "ordersuccess" ,
    component : () => import( '../views/PaySuccess')
  },
  {
    path: "/page/pay/cancel" ,
    name: "ordercancel" ,
    component : () => import( '../views/PayCancel')
  },
  ,
  {
    path: "/messageCenter" ,
    name: "MessageCenter" , 
    component: () => import("../views/MessageCenter") ,
  },{
    path: "/aiphoto",
    name: "Aiphoto",
    component: () => import("../views/Aiphoto"),
  },{
    path: "/replaceFace" ,
    name: "ReplaceFace",
    component: () => import("../views/ReplaceFace"),
  },{
    path: "/HDrestoration" , 
    name : "HDrestoration"  ,
    component : () => import("../views/HDrestoration")
  },{
    path: "/HDzoom" , 
    name : "HDzoom"  ,
    component : () => import("../views/HDzoom")
  },{
    path: "/AIfilter" ,
    name : "AIfilter"  ,
    component : () => import("../views/AIfilter")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  // console.log( to )
  if( from.query.lang ){
    to.query.lang = from.query.lang
  } else if( to.matched.length == 0){
    next({
      path : "/"     
    })
  } else if(to.params.router == 'explore' || to.path == "/explore" || to.params.router == "share" || to.path == "/share" || to.params.router == 'aiQaScheme' || to.path == '/ai-qa-scheme'
    || to.params.router == "aiQa" || to.path == "/ai-qa" || to.params.router == "aiQaSchemeqa" || to.path == "/ai-qa-schemeqa"
    || to.params.router == "textImage" || to.path == "/text-image" || to.params.router == "imageToImage" || to.path == "/image-image"
    || to.params.router == "ReplaceFace" || to.path == "/replaceFace" || to.path == "/aiphoto" || to.path == '/HDrestoration' || to.path == '/HDzoom'
    || to.path == "/AIfilter"
  ){
    next()
  } else if( to.path != '/' &&  to.path != '/login' && !localStorage.getItem('x-token') ){
    next({
      path : "/" , 
    	params : { unlogin: true }, 
    })
  }
  next()
})




export default router
