import request from '@/utils/request'
import axios from 'axios'

export function consumeRecordReq(data) {
  return request({
    url:  '/consume/record',
    method: 'post',
    data: data,
  })
}

//rechargeRecordReq
export function rechargeRecordReq(data) {
  return request({
    url:  '/recharge/record',
    method: 'post',
    data: data,
  })
}


