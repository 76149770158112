<template>
  <b-row class="navbar"  >
    <b-col cols="6" style="padding-left:0;padding-right:0;" class="nav-l">
      <a href="https://canvascraft.art/">
        <el-image style="width: 36px; height: 36px;margin-right: 8px;cursor: pointer;" 
            alt="博色AI"
            :src="require('../assets/img/logo.png')"
            fit="contain" @click="homeBack">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
      </a>
      <span style="cursor: pointer;" @click="homeBack">Boser AI</span>
      
    </b-col>
    <b-col cols="6" style="text-align:right;padding-left: 0;padding-right: 0;">
      <span v-show="iconshow" class="d-flex" style="justify-content: flex-end; align-items: center;flex-wrap: nowrap;">
        <!-- <b-icon class="d-lg-none d-md-none d-sm-block d-block needsclick" icon="list" 
          font-scale="1.4" v-b-toggle.sidebar-no-header style="color:#fff"></b-icon> -->
        <div class="d-flex needsclick" style="color:#fff ;align-items: center;" v-if="!tokenVuex">
          <!-- <GroupScan style="margin-right: 16px;"></GroupScan> -->
          <el-button type="primary" round @click="login_click"  style="cursor: pointer; margin-left: 5px;background-color: #006FFF;" >Login</el-button>
        </div>
        <template v-else >
          <div v-if="ac_data.vip" class="d-flex" style="align-items: center;margin-right: 24px;">
            <el-image style="width: 30px; height: 30px;margin-right: 1px;cursor: pointer;margin-bottom: 2px;" 
                :src="require('../assets/img/membericon.png')"
                fit="contain" >
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
            <span style="font-size: 16px;font-weight: 500;color: #E3A048;line-height: 30px;height: 30px;">Vip until {{  ac_data.endDate.split(" ")[0]  }}</span>
          </div>

          <template v-if="adultSwitch">
            <el-image style="width: 30px; height: 30px;cursor: pointer;margin-right: 16px;flex-shrink: 0;" fit="cover" :src="require('../assets/img/nav/eyeopen.png')" v-if="adultImageShow"  @click="adultSwitchChange">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
            <el-image style="width: 30px; height: 30px;cursor: pointer;margin-right: 16px;flex-shrink: 0;" fit="cover" :src="require('../assets/img/nav/eyeclose.png')" v-else @click="adultSwitchChange">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </template>



          <el-image style="width: 190px; height: 34px;margin-right: 18px;cursor: pointer;margin-bottom: 2px;" 
              :src="require('../assets/img/nav/cuxiao.png')"
              fit="contain"  @click="rechargeShowClick ">
            <div slot="placeholder" class="image-slot"></div>
          </el-image>

          <!-- <GroupScan style="margin-right: 16px;"></GroupScan> -->
          <infoCenterPop style="margin-right: 16px;"></infoCenterPop>
          <PersonalPop :acData="ac_data" @ListenPerson="ListenPerson" @popShow="popShow" v-if="personPopSHow" :key="timestamp"></PersonalPop>

        </template>
      </span>
    </b-col>

    <Recharge :dialogRechargeVisible.sync="rechargeShow" v-if="rechargeShow" :rechargeType="rechargeType"></Recharge>
    <Record :dialogRecordVisible.sync="recordShow" :dialogRecordType.sync="recordType" :dialogFeedbackVisible.sync="feedbackShow" v-if="recordShow"></Record>
    <FeedBack :dialogFeedbackVisible.sync="feedbackShow" v-if="feedbackShow"></FeedBack>
    <CropperDialog :dialogUploadAvaVisible.sync="uploadAvaShow" :option="cropoption"  v-if="uploadAvaShow"></CropperDialog>
    <AccountSettings :dialogAccountSettingsShow.sync="accountSettingsShow" v-if="accountSettingsShow" @userChangeListen="userChangeListen" @adultSwitchChangeListen="adultSwitchChangeListen"></AccountSettings>
    <PayStatus  :dialogPayStatusShow.sync="PayStatusShow"  :payStatus="payStatus"  v-if="PayStatusShow" ></PayStatus>
  
  </b-row>
</template>

<script>
import common from '../utils/common';
import { sms_req , login_req , login_out_res , user_account_req , user_adultSwitch_req , event_log_req } from "@/api/user";
import { msg_typeCount_req } from "@/api/message";
import Recharge from "@/components/Recharge" ;
import Record from "@/components/Record"
import FeedBack from "@/components/FeedBack"
import CropperDialog from "@/components/CropperDialog"
import GroupScan from "@/components/GroupScan"
import infoCenterPop from "@/components/infoCenterPop"
import PersonalPop from "@/components/PersonalPop"
import AccountSettings from "@/components/AccountSettings"
import PayStatus from "@/components/PayStatus"
import { getUUID } from "@/utils/bose";
import md5 from 'js-md5';


export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    iconshow: {
      type: Boolean,
      default: true
    } ,
    needUpdate : {
      type: Number ,
      default : 1 , 
    },
    needRecharge : {
      type : Number,
    }

  },
  components : { 
    Recharge,
    Record,
    FeedBack,
    CropperDialog ,
    GroupScan ,
    PersonalPop ,
    AccountSettings , 
    infoCenterPop ,
    PayStatus , 
    
  },
  name: "Navbar",
  data() {
    return {
      navdata: [],
      form: {
        tel: null,
        code: null,
      },
      bVerification : false ,
      countDown: "发送验证码", // 倒计时
      ac_data : {} ,//账户信息
      rechargeShow : false ,
      rechargeType : 8 ,  
      recordShow : false , 
      recordType : "1" ,
      feedbackShow : false , 
      uploadAvaShow : false , 
      accountSettingsShow : false  ,
      personPopSHow : true ,
      timestamp : undefined , 

      cropoption: {
        img: '', // 裁剪图片的地址
        info: false, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        // autoCropWidth: 572, // 默认生成截图框宽度
        // autoCropHeight: 572, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      adultSwitch : false  ,
      PayStatusShow : false  , 
      paystatus:"success"
    }
  },
  mounted() {
    // this.navdata = common.getNavData()
    this.navdata = this.$i18n.messages[this.$i18n.locale].getNavData;
    this.timestamp = Date.parse(new Date());


    let page = undefined ;
    if(  this.$route.name == 'home' ){
      page = 'home'
    } else if( this.$route.name  == 'aiQa' ){
      page = 'qa'
    } else if( this.$route.name  == 'textImage' ){
      page = 'textimage'
    } else if( this.$route.name  == 'imageToImage' ){
      page = 'imageimage'
    } else if( this.$route.name  == 'explore' ){
      page = 'explore'
    } else if( this.$route.name  == 'Personal' ){
      page = 'personal'
    }
    if( this.tokenVuex ){ //如果登录了 去获取信息
      this.getUserAccountInfo().then( () => {
        if( page ){
          this.event_active_log( page )
        }
      })   //获取账户信息

      this.getMsgCount()
    } else {
      if( page ){
        this.event_active_log( page )
      }
    }

    if( this.getQueryVariable("paystatus") ){
      if( this.getQueryVariable("paystatus") == "success" ){
        this.$router.replace({
            name:"home",
            params:{
              paystatus:"success"
            }
        })
        this.PayStatusShow = true ;
        this.payStatus = 'success'
      } else if( this.getQueryVariable("paystatus") == "cancel"  ){
        this.$router.replace({
            name:"home",
            params:{
              paystatus:"cancel"
            }
        })
        this.PayStatusShow = true ;
        this.payStatus = 'cancel'
      }
      // console.log( this.$route.params.paystatus  )
    }
  },  
  beforeDestroy(){
    this.personPopSHow = false ; 
  },
  computed: {
    validationPhone() {
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.userForm.phone)) {
        return false;
      } else {
        return true;
      }
    },
    tokenVuex(){
      // 存储位置： store.state.{模块名,在store/index.js中module下的}.{state中定义的属性}
      return this.$store.state.user.token
    },
    accountSettingVuex(){
      return this.$store.state.user.accountSetting
    },
    adultImageShow(){
      return this.$store.state.user.adultImageShow
    }
  },
  methods: {
    event_active_log( page ){
      let cnl = localStorage.getItem("cc") ? localStorage.getItem("cc") + '-pc': 'none-pc' 
      let uid = this.ac_data.uid ? this.ac_data.uid : getUUID()  ; 
      var ts = Date.parse(new Date());
      let detail_str =  JSON.stringify( { page : page } ) 
      let sign = md5( 'cnl' + cnl  + 'detail' + detail_str +   'event' + "active"   + 'ts' + ts  + 'uid' + uid  + 'yEBgmO69')
      event_log_req({
        // category : '' ,
        cnl : cnl , 
        detail : detail_str , 
        event : "active" ,
        ts : ts ,
        uid : uid ,
        sign : sign , 
      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        name: null,
        code: null,
      };
      this.bVerification = false // 节流

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async onSubmit() {
      // console.log( this.form)
      const { success , data } = await login_req( this.form );
      if ( success && data ) {
        localStorage.setItem( "x-token",  data  )
        
        this.$toast.open({
          message: 'Login Successful',
          position: 'top',
          duration : 3000,
          type: 'success',
        });
        this.$bvModal.hide('modal-center') ;
        this.$store.commit('user/change_token') ;
      } 

      this.resetForm()
    } ,
    async sendCode() {
        if (this.bVerification) return;
        this.bVerification = true;
        const { success , data } = await sms_req({
            tel: this.form.tel
        });
        if (success === true && data  === true ) {
          this.$toast.open({
            message: '发送验证码',
            position: 'top',
            duration : 3000,
            type: 'success',
          });
          let countDown = 59;
          const auth_time = setInterval(() => {
              this.countDown = countDown--;
              if (this.countDown <= 0) {
                  this.bVerification = false;
                  this.countDown = "发送验证码";
                  clearInterval(auth_time);
              }
          }, 1000);
        }  else {
          this.bVerification = false;
          this.countDown = "发送验证码";
        }
    } ,
    async login_out(){
      localStorage.removeItem( "x-token" ) 
      this.$store.commit('user/change_token') ;
      this.$toast.open({
          message: 'Exit successfully',
          position: 'top',
          duration : 3000,
          type: 'success',
        });

      this.$router.push({
        name: "home", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        params: {
          unlogin: true 
        }
      });
      if( !this.tokenVuex ) {
        return;
      }
      
      const { success , data } = await login_out_res() ;
      if (success === true && data  === true ) {
        localStorage.removeItem( "x-token" ) 
        this.$store.commit('user/change_token') ;
      } 
      this.resetForm()
    } ,
    async getUserAccountInfo(){
      const { data , success} = await user_account_req()
      if( success ) {
        if( data.ext ){
          data.ext = JSON.parse( data.ext )
          this.adultSwitch = data.ext && data.ext.adultSwitch  ? data.ext.adultSwitch : false  
        }
        this.ac_data = data
      }
      this.timestamp = Date.parse(new Date());
    },
    dropdown_show_method(){
      if( this.tokenVuex ){ //如果登录了 去获取信息
        this.getUserAccountInfo() //获取账户信息
      }
    } ,
    homeBack(){
      this.$router.push({
        path: `/`,
      })
    },
    config_square_img(){
      this.$router.push({
        path: `/square_config`,
      })
    },
    login_click(){
      this.$emit('update:dialogLoginVisible',  true ) // 传递更新数值

    },
    rechargeShowClick(){
      this.rechargeType = 8 ;
      this.rechargeShow = true ;
      let cnl = localStorage.getItem("cc") ? localStorage.getItem("cc") + '-pc': 'none-pc' 
      let uid = this.ac_data.uid ? this.ac_data.uid : getUUID  ; 
      var ts = Date.parse(new Date());
      let sign = md5( 'cnl' + cnl  +    'event' + 'recharge_click'   + 'ts' + ts  + 'uid' + uid  + 'yEBgmO69')
      event_log_req({
        // category : '' ,
        cnl : cnl , 
        event : 'recharge_click' ,
        ts : ts ,
        uid : uid ,
        sign : sign , 
      })
    },
    recordShowClick( type ){
      this.recordType = type ;
      this.recordShow = true ;
    },
    accountSettingsClick(){
      this.accountSettingsShow = true ; 
    },
    feedbackClick(){
      this.feedbackShow = true ;
    }, 
    getFile(file, fileList , scope) {
      // console.log
      const typeArry = ['.jpg', '.png', '.bmp', '.JPG', '.PNG', '.BMP', '.gif', '.GIF' , '.jpeg' , '.JPEG'];
      const type = file.name.substring(file.name.lastIndexOf('.'));
      const isImage = typeArry.indexOf(type) > -1;
      if (!isImage) {
        this.$toast.open({message: 'The uploaded file must be an image',position: 'top', duration: 3000,type: 'error' });
        return 
      }

      let imgSize = Number(file.size / 1024 / 1024);
      if (imgSize > 2) {
        this.$toast.open({
          message: 'The maximum image size is 2MB, please try again',
          position: 'top',
          duration: 3000,
          type: 'error',
        });
        return
      }

      if (fileList&&fileList.length>=2) {
        fileList.shift();
      }
      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(async () => {
          // base64方式
          // this.option.img = await fileByBase64(file.raw)
          this.cropoption.img = URL.createObjectURL(file.raw)
          this.uploadAvaShow = true ; 
      })
    },
    ListenPerson( obj ) {
      if( obj.num == 1 ){
        this.login_out();
      } else if( obj.num == 2 ) {
        this.rechargeType = obj.rechargeType ? obj.rechargeType : 8 
        this.rechargeShow = true ;      
      } else if( obj.num == 3 ) {
        this.accountSettingsShow = true ; 
        this.$store.commit('user/change_accountSetting' , true ) 
      } else if( obj.num == 4 ) {
        this.feedbackClick();
      } else if( obj.num == 5 ) {
        this.recordShowClick('2')
      } else if( obj.num == 6 ) {
        this.recordShowClick('1')
      } else if( obj.num == 7 ) {
        //    
        let obj = {} ;
        obj.router = "personal"  ;
        obj.uid = this.ac_data.uid ;
        this.$router.push({
          name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
          params: obj
        });
        // this.$router.push({
        //   path: `/personal`,
        // })
      } else if( obj.num == 8 ) {
        let obj = {} ;
        obj.router = "taskCenter"  ;
        this.$router.push({
          name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
          params: obj
        });
      }

    },
    popShow(){
      this.getUserAccountInfo() //获取账户信息
    },
    userChangeListen(){
      this.timestamp = Date.parse(new Date());
      this.getUserAccountInfo() //获取账户信息
    },
    getMsgCount(){
      this.$store.dispatch('message/changeMesNumAct')
    },
    adultSwitchChange(){
      // this.adultImageShow = !this.adultImageShow
      this.$store.commit('user/change_adultshow' , !this.adultImageShow ) ;
      // user_adultSwitch_req(  this.ac_data.uid  ,  flag  ).then( res=>{
      //   this.getUserAccountInfo();
      //   let _num =  this.needUpdate + 1 ;
      //   //改了成人 
      //   this.$store.commit('user/change_adult') ;
      // })
    } ,
    adultSwitchChangeListen(){
      let _num =  this.needUpdate + 1 ;
      //改了成人 
      this.$store.commit('user/change_adult') ;
    } ,
    getQueryVariable(variable){
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
      }
      return(false);
    },
  } ,
  watch: {
    // 如果 `question` 发生改变，这个函数就会运行
    rechargeShow: function (newQuestion, oldQuestion) {
      this.getUserAccountInfo() //获取账户信息

      let _num =  this.needUpdate + 1 ;
      // this.$emit('update:needUpdate', _num ) // 传递更新数值
    } ,
    uploadAvaShow : function ( newQuestion , oldQuestion ){
      if( !newQuestion ){
        this.$refs['my-upload'].clearFiles();
        this.getUserAccountInfo() //获取账户信息
      } 
    } ,
    tokenVuex(){
      this.getUserAccountInfo() //获取账户信息
    },
    accountSettingVuex( newQuestion ){
      if( newQuestion ){
        this.accountSettingsShow = true ; 
      }
    },
    accountSettingsShow: function (newQuestion, oldQuestion) {
      this.getUserAccountInfo() //获取账户信息
      let _num =  this.needUpdate + 1 ;
      // this.$emit('update:needUpdate', _num ) // 传递更新数值
    } ,
    needRecharge(newQuestion){
      this.rechargeShow = true ;
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dropdown-toggle {
  line-height: 1;
}
::v-deep .dropdown-menu {
  background-color: #1B1D2E;
  padding: 8px;
  top: 5px !important;
  .dropdown-item {
    color: #9A9AB7;
    font-size: 15px;
    font-weight: 400;
    color: #9A9AB7;
    width: 100%;
    box-sizing: border-box;
  }
  .dropdown-item:hover, .dropdown-item:focus {
    background: #252739;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 500;
    color: #DDDDEA;
  }
}

::v-deep .dropdown-menu:before{
    display:block;
    content:'';
    border-width: 6px 6px 6px 6px;
    border-style:solid;
    border-color:transparent transparent #2C2F42 transparent;
    
    /* 定位 */
    position:absolute;
    right : 8px ;
    top:-12px;
}


::v-deep .btn {
  padding: 0;
}

.navbar {
  margin-left: 0;
  margin-right: 0;
  min-height: calc(2em + 1vw);
  height: 66px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: #111225;
  padding: 15px 18px; 
  box-sizing: border-box;
  z-index: 1999;
  .title {
    font-size: calc(1em + 1vw);
    color: #fff;
  }
  .nav-l {
    display: flex;
    align-items: center;
  }
}

.home-icon {
  color: #fff;
}

a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}

.nav-line>a {
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.nav-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 15px;
}


// .modal-dialog {
//     max-width: 300px;
//     margin: 1.75rem auto;
// }

</style>