import axios from 'axios'
import Vue from 'vue';
// import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/bose'
import { tansParams, blobValidate } from "@/utils/bose";
import qs from 'qs'
import {  getBaseUrl } from "@/utils/bose";
import Cookies from 'js-cookie'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// create an axios instance
const service = axios.create({
  baseURL : getBaseUrl() , 

  withCredentials: true, // send cookies when cross-domain requests
  timeout: 150000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if ( localStorage.getItem( 'x-token' ) ) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      
      config.headers['x-token'] = getToken()
    }

    
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if ((config.method === 'post' || config.method === 'put')) {
      const requestObj = {
        url: config.url,
        // data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        data : qs.stringify(
          config.data
        ),
        time: new Date().getTime()
      }
    }
    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;

    //登录失败删除token
    if( res.success == false && res.errorCode == "11008" ){
      localStorage.removeItem( "x-token" ) ;
      store.commit('user/change_token') ;

      
    } else if( res.success == false ){
      if( Cookies.get("expires") ){

      } else {
        let seconds = 1;
        let expires = new Date(new Date() * 1 + seconds * 1000);
        Cookies.set('expires', 'expires', { expires: expires }); // 10 秒
        Vue.$toast.open({
          message: res.errorMessage ,
          position: 'top',
          duration : 3000,
          queue : true   , 
          type: 'error',
        })
      }
    }
    
    
    return res 
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if( Cookies.get("expires") ){

    } else {
      let seconds = 1;
      let expires = new Date(new Date() * 1 + seconds * 1000);
      Cookies.set('expires', 'expires', { expires: expires }); // 10 秒
      Vue.$toast.open({
        message: 'Please try again later' ,
        position: 'top',
        duration : 3000,
        queue : true   , 
        type: 'error',
      })
    }



    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
