export default {
    namespaced: true,
    state : {
      // 这里放要管理的状态
      token : localStorage.getItem('x-token') ? localStorage.getItem('x-token') : null ,
      infochange : 1 ,
      accountSetting : false , 
      adult : 1 ,
      adultImageShow : ( localStorage.getItem('adultImageShow') )  ? (localStorage.getItem('adultImageShow') == 2 ? false : true ) : false  , 
    },
  mutations:{
      // // 定义待发送的事件，第一个参数会注入当前的state
      change_token(state){
          state.token = localStorage.getItem('x-token') ? localStorage.getItem('x-token') : null 
      },
      change_info(state){
        state.infochange += 1 ;
      } ,
      change_accountSetting(state , flag ){
        state.accountSetting = flag ;
      },
      change_adult(state){
        state.adult += 1 ;
      } ,
      change_adultshow( state , flag ){
        state.adultImageShow  = flag ;
        localStorage.setItem( 'adultImageShow' , flag ? "1" : "2" )
      }
  }
}

