import request from '@/utils/request'
import axios from 'axios'

// let api = process.env.VUE_APP_API_BASE_URL
let api = ""

//
export function sms_req(query) {
  return request({
    url:  '/common/sms',
    method: 'get',
    params: query
  })
}


export function login_req(data) {
  return request({
    url:  '/common/login',
    method: 'post',
    data: data,
    // timeout: 10 // request timeout

  })
}


export function gg_login_req(data) {
  return request({
    url:  '/common/glogin',
    method: 'post',
    data: data,
    // timeout: 10 // request timeout

  })
}



export function login_out_res() {
  return request({
    url:  '/common/logout',
    method: 'post'
  })
}


export function user_info_req(query) {
  return request({
    url:  '/user/' + query.id,
    method: 'get'
    // params: query
  })
}


export function user_account_req(query) {
  return request({
    url:  '/user/ac' ,
    method: 'get'
    // params: query
  })
}


//wx_login_req

export function wx_login_req(query) {
  return request({
    url:  '/common/wxLogin' ,
    method: 'get',
    params: query
  })
}


export function user_photo_req(parameter) {
  return request({
    url: '/user/photo',
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function user_setting_req( parameter ) {
  return request({
    url:  '/user/setting',
    method: 'post' ,
    data: parameter,
  })
}

export function user_adultSwitch_req(query , flag ) {
  return request({
    url:  '/user/adultSwitch/' + query ,
    method: 'get',
    params : {
      isOpen: flag
  } , 
  })
}


export function user_invoke_req(query  ) {
  return request({
    url:  '/userac/invoke'  ,
    method: 'get',
    params : query
  })
}

//event/log 

export function event_log_req(parameter  ) {
  return request({
    url:  '/event/log'  ,
    method: 'post' ,
    data: parameter,
  })
}
